export const order_status = [
    {
        value: 'order received',
        label: 'Order Received',
    },
    {
        value: 'order packed',
        label: 'Order Packed',
    },
    {
        value: 'on the way',
        label: 'On the Way',
    },
    {
        value: 'delivered',
        label: 'Delivered',
    },
    {
        value: 'cancelled',
        label: 'Cancelled',
    },
]