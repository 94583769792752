import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//importing components
import Login from "./Views/Login/Login";
import Landing from "./Views/Landing/Landing";
import PrivateRoute from "./HOComponents/PrivateRoute";

// import SuperAdminRoute from "./HOComponents/SuperAdminRoute";

import Categories from './Views/Categories/Categories';
import SubCategories from './Views/SubCategories/SubCategories';
import Products from "./Views/Products/Products";
import CreateProduct from "./Views/CreateProduct/CreateProduct";
import EditProduct from "./Views/EditProduct/EditProduct";
import SubSubCategories from './Views/SubSubCategories/SubSubCategories';
import Users from "./Views/Users/Users";
import Orders from "./Views/Orders/Orders";
import ContactUs from "./Views/ContactUs/ContactUs";
import Newsletter from "./Views/Newsletter/Newsletter";
import GlobalData from "./Views/GlobalData/GlobalData";
import Brands from "./Views/Brands/Brands";
import Manufacturers from "./Views/Manufacturers/Manufacturers";

const App = () => {

    return (
        <Router>
            <Route path="/login" component={Login} exact />
            <Switch>
                <PrivateRoute roles={["admin"]} path="/" component={Landing} exact />
                <PrivateRoute roles={["admin"]} path="/users" component={Users} exact />
                <PrivateRoute roles={["admin"]} path="/brands" component={Brands} exact />
                <PrivateRoute roles={["admin"]} path="/manufacturers" component={Manufacturers} exact />
                <PrivateRoute roles={["admin"]} path="/categories" component={Categories} exact />
                <PrivateRoute roles={["admin"]} path="/sub-categories" component={SubCategories} exact />
                <PrivateRoute roles={["admin"]} path="/sub-subcategories" component={SubSubCategories} exact />
                <PrivateRoute roles={["admin"]} path="/products" component={Products} exact />
                <PrivateRoute roles={["admin"]} path="/products/create" component={CreateProduct} exact />
                <PrivateRoute roles={["admin"]} path="/products/edit/:productID" component={EditProduct} exact />
                <PrivateRoute roles={["admin"]} path="/orders" component={Orders} exact />
                <PrivateRoute roles={["admin"]} path="/contact-us" component={ContactUs} exact />
                <PrivateRoute roles={["admin"]} path="/newsletter" component={Newsletter} exact />
                <PrivateRoute roles={["admin"]} path="/global-data" component={GlobalData} exact />
            </Switch>
        </Router>

    );
}

export default App;
