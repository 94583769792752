import { Button, Form, Input, Row, Col, message } from 'antd';
import { useState, useEffect } from 'react';
import ProductDescriptionEditor from '../../ProductDescriptionEditor/ProductDescriptionEditor';
import styles from '../ManageProductStyles/Styles.module.scss';
import { IoIosClose } from 'react-icons/io';
import axios from '../../../config/axios';

const ProductDescription = ({ product }) => {
    const [form] = Form.useForm();
    const [description, setDescription] = useState(() => product.description || "Remove This Text and Add In Depth Product Description Here");
    const [legalDisclaimer, setLegalDisclaimer] = useState(() => product.legal_disclaimer || "Remove This Text and Add In Depth Legal Disclaimer Here");
    const [keyFeatures, setKeyFeatures] = useState([]);
    const [keyFeatureValue, setKeyFeatureValue] = useState('');
    const [loading, setLoading] = useState(false);

    // setting initial key features
    useEffect(() => {
        if (product.key_features) {
            let features = product.key_features.split(',');
            for (const item of features) {
                let OldKeyFeature = {
                    value: item,
                };

                setKeyFeatures((keyFeatures) => keyFeatures.concat(OldKeyFeature))
            }
        }
    }, [product]);

    // defining addKeyFeatures function
    const addKeyFeatures = () => {
        if (!keyFeatureValue) {
            message.error('Enter Key Features')
        }

        let newKeyFeature = {
            value: keyFeatureValue,
        };

        setKeyFeatures((keyFeatures) => keyFeatures.concat(newKeyFeature))

        setKeyFeatureValue('')
    }

    // defining updateDescription function
    const updateDescription = async (values) => {
        if (!description) {
            message.error('Description is required')
            return;
        }
        if (!legalDisclaimer) {
            message.error('Legal Disclaimer is required')
            return;
        }

        if (keyFeatures.length === 0) {
            message.error('Add at least one key feature')
            return;
        }

        values.description = description;
        values.legal_disclaimer = legalDisclaimer
        values.key_features = keyFeatures.map(({ value }) => value).join(",");

        console.log(values);
        setLoading(true);
        await axios.patch(`/admin/products/${product.id}`, values, { withCredentials: true })
        message.success("Description Updated successfully")
        setLoading(false)
    }

    return (
        <Form
            form={form}
            onFinish={updateDescription}
            layout='horizontal'
            initialValues={
                product && {
                    ...product
                }
            }
        >
            <Row>
                <Col span={24}>
                    {description && (
                        <Form.Item name="description" label="Product Description">
                            <ProductDescriptionEditor
                                description={description}
                                setDescription={setDescription}
                            />
                        </Form.Item>
                    )}
                </Col>

                <Col span={24}>
                    <Form.Item label='About this product' name={'about_this_product'}>
                        <Input.TextArea />
                    </Form.Item>
                </Col>

                <Col span={18}>
                    <div className={styles.formItem}>
                        <label htmlFor="key_features">Product Key Features: </label>
                        <Input
                            placeholder='Enter Product Key Features'
                            onChange={(e) => setKeyFeatureValue(e.target.value)}
                            value={keyFeatureValue}
                        />
                    </div>
                </Col>
                <Col span={6}>
                    <Button type="primary" onClick={addKeyFeatures} style={{ marginLeft: 10 }}>Add Key Feature</Button>
                </Col>

                <Col span={24} style={{ marginTop: '2rem' }}>
                    {keyFeatures.length > 0 && (
                        <div className={styles.formItem}>
                            <label htmlFor='variant_prices'>Added Key features : </label>
                            <div className={styles.normal_price_box}>
                                {keyFeatures.map((feature, index) => {
                                    return (
                                        <Button size='small' type='primary' key={index} onClick={() => setKeyFeatures(keyFeatures.filter((current) => current.value !== feature.value))}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {feature.value}
                                                <IoIosClose size={20} />
                                            </div>
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Col>

                <Col span={24} style={{ marginTop: '2rem' }}>
                    {legalDisclaimer && (
                        <Form.Item name="legal_disclaimer" label="Legal Disclaimer">
                            <ProductDescriptionEditor
                                description={legalDisclaimer}
                                setDescription={setLegalDisclaimer}
                            />
                        </Form.Item>
                    )}
                </Col>

                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                        <Button
                            style={{ width: '100%', maxWidth: '150px', minWidth: '150px' }}
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

        </Form>
    );
}

export default ProductDescription;