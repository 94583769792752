import { useState } from 'react';

// importing styles
import styles from './ImageUploader.module.scss';
// importing components
import { message } from 'antd';
import Loader from '../Loader/Loader';

// importing axios
import axios from '../../config/axios';


const ImageUploader = ({ setter, multiple }) => {

    const [loading, setLoading] = useState(false);

    //defining imageUploadHandler function
    const imageUploadHandler = async (e) => {
        // get files from event
        const files = e.target.files;

        // running loop over files
        for (const file of files) {
            let formData = new FormData();
            formData.append("file", file);

            // request to server here
            try {
                setLoading(true);
                const response = await axios.post(`/admin/fundamentals/upload-file`, formData, { withCredentials: true });

                // concat if multiple true, else set last uploaded image
                if (multiple) {
                    setter((images) => images.concat(response.data.secure_url));
                } else {
                    setter([response.data.secure_url]);
                }

            } catch (error) {
                message.error('A server side error occurred!');
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className={styles.file_upload}>
            <div className={styles.image_upload_wrap}>
                <input
                    className={styles.file_upload_input}
                    type="file"
                    multiple={multiple}
                    onChange={imageUploadHandler}
                />
                <div className={styles.drag_text}>
                    {loading ?
                        <Loader height={"159px"} />
                        :
                        <h3>Drag and drop or click to add Images</h3>
                    }
                </div>
            </div>
        </div>
    );
};

export default ImageUploader;