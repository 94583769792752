import React from 'react';
import Layout from '../../Components/Layout/Layout';
import ManageProduct from '../../Components/Products/ManageProductComponents/ManageProduct';

const CreateProduct = () => {
    return (
        <Layout>
            <ManageProduct />
        </Layout>
    );
};

export default CreateProduct;