import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import axios from "../../../config/axios";
import moment from "../../../config/moment";
import ProductDescriptionEditor from "../../ProductDescriptionEditor/ProductDescriptionEditor";

const ProductInfo = ({ product }) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [facts, setFacts] = useState([]);
    const [typicalValue, setTypicalValue] = useState("");
    const [p100, setP100] = useState("");
    const [p250, setP250] = useState("");
    const [listOfIngredient, setListOfIngredient] = useState(
        product.list_of_ingredients || "Remove This Text and Add List of Ingredients Here"
    );

    // setting initial key features
    useEffect(() => {
        if (product.facts) {
            setFacts(JSON.parse(product.facts));
        }
    }, [product]);

    // defining updateDescription function
    const updateInfo = async (values) => {
        // console.log(values);
        values.facts = JSON.stringify(facts);
        values.list_of_ingredients = listOfIngredient;
        setLoading(true);
        await axios.patch(`/admin/products/${product.id}`, values, { withCredentials: true });
        message.success("Info Updated successfully");
        setLoading(false);
    };

    const addFact = () => {
        setFacts((facts) =>
            facts.concat({
                typical_value: typicalValue,
                p100: p100,
                p250: p250,
            })
        );
        setTypicalValue("");
        setP100("");
        setP250("");
    };

    return (
        <Form
            form={form}
            onFinish={updateInfo}
            layout="horizontal"
            initialValues={
                product && {
                    ...product,
                    best_before: product.best_before ? moment(product.best_before) : null,
                }
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item name="nutri_score" label="Nutri Score">
                        <Select placeholder="Select Nutri Score">
                            <Select.Option value="a">A</Select.Option>
                            <Select.Option value="b">B</Select.Option>
                            <Select.Option value="c">C</Select.Option>
                            <Select.Option value="d">D</Select.Option>
                            <Select.Option value="e">E</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="storage_temperature" label="Storage Temperature">
                        <Select placeholder="Select Storage Temperature">
                            <Select.Option value="Normal (12-30oC Max)">Normal (12-30oC Max)</Select.Option>
                            <Select.Option value="Normal 18-20oC">Normal 18-20oC</Select.Option>
                            <Select.Option value="Refrigerated 2-7oC Max">Refrigerated 2-7oC Max</Select.Option>
                            <Select.Option value="Frozen -18 to -24oC">Frozen -18 to -24oC</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24} style={{ display: "flex" }}>
                    <Form.Item label="" valuePropName="checked" name={"is_gluten_free"}>
                        <Checkbox>Gluten Free</Checkbox>
                    </Form.Item>
                    <Form.Item label="" valuePropName="checked" name={"is_vegan"}>
                        <Checkbox>Vegan</Checkbox>
                    </Form.Item>
                    <Form.Item label="" valuePropName="checked" name={"is_refundable"}>
                        <Checkbox>Refundable</Checkbox>
                    </Form.Item>
                    <Form.Item label="" valuePropName="checked" name={"has_free_delivery"}>
                        <Checkbox>Has Free Delivery</Checkbox>
                    </Form.Item>
                    <Form.Item label="" valuePropName="checked" name={"is_non_refundable"}>
                        <Checkbox>Is Non Refundable</Checkbox>
                    </Form.Item>
                    <Form.Item label="" valuePropName="checked" name={"has_contactless_delivery"}>
                        <Checkbox>Contactless Delivery</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={24} style={{ display: "flex" }}>
                    <Form.Item label="" valuePropName="checked" name={"leaf_sign_1"}>
                        <Checkbox>
                            <img src="https://desifresh.co/icon/ps/leaf.svg" width={"30px"} alt="" />
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="" valuePropName="checked" name={"leaf_sign_2"}>
                        <Checkbox>
                            <img src="https://desifresh.co/icon/ps/bio.svg" width={"30px"} alt="" />
                        </Checkbox>
                    </Form.Item>
                    {/*<Form.Item label='' valuePropName='checked' name={'percent_sign'}>*/}
                    {/*    <Checkbox>*/}
                    {/*        <img src="/misc/percent_sign.png" width={'30px'} alt="" />*/}
                    {/*    </Checkbox>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="" valuePropName="checked" name={"new_sign"}>
                        <Checkbox>
                            <img src="https://desifresh.co/icon/ps/new.svg" width={"30px"} alt="" />
                        </Checkbox>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="" valuePropName="checked" name={"freezing"}>
                        <Checkbox>Freezing</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="" valuePropName="checked" name={"refrigeration"}>
                        <Checkbox>Refrigeration</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="name_of_food" label="Name of Food">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="list_of_ingredients" label="List of Ingredients">
                        <ProductDescriptionEditor description={listOfIngredient} setDescription={setListOfIngredient} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="allegen_labelling" label="Allegen Labelling">
                        <Input.TextArea />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="batch_no" label="Lot/Batch No">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="current_lot_unit" label="Lot/Batch Stock">
                        <Input type={"number"} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="best_before" label="Best Before">
                        <DatePicker />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="company_address" label="Company Address">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="origin_of_making" label="Origin of Making">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="instructions_of_use" label="Instructions of Use">
                        <Input.TextArea />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Select
                        placeholder="Select Typical Value"
                        value={typicalValue}
                        onChange={(value) => setTypicalValue(value)}
                        style={{ width: "100%" }}
                    >
                        <Select.Option value={"Calories (KJ)"}>Calories (KJ)</Select.Option>
                        <Select.Option value={"Calories (Kcal)"}>Calories (Kcal)</Select.Option>
                        <Select.Option value={"Fat"}>Fat</Select.Option>
                        <Select.Option value={"Saturated Fat"}>Saturated Fat</Select.Option>
                        <Select.Option value={"Carbohydrates"}>Carbohydrates</Select.Option>
                        <Select.Option value={"Sugars"}>Sugars</Select.Option>
                        <Select.Option value={"Added Sugars"}>Added Sugars</Select.Option>
                        <Select.Option value={"Dietary Fiber"}>Dietary Fiber</Select.Option>
                        <Select.Option value={"Protein"}>Protein</Select.Option>
                        <Select.Option value={"Salt"}>Salt</Select.Option>
                    </Select>
                </Col>
                <Col span={6}>
                    <Input placeholder="per 100 g (%)" onChange={(e) => setP100(e.target.value)} value={p100} />
                </Col>
                <Col span={6}>
                    <Input placeholder="per portion/250g" onChange={(e) => setP250(e.target.value)} value={p250} />
                </Col>
                <Col span={6}>
                    <Button onClick={addFact}>Add</Button>
                </Col>

                <Col span={24}>
                    <table className="table">
                        <tr>
                            <th>Typical Values</th>
                            <th>per 100 g (%)</th>
                            <th>per portion/250g</th>
                        </tr>
                        {facts.map((fact, index) => {
                            return (
                                <tr key={index}>
                                    <td>{fact.typical_value}</td>
                                    <td>{fact.p100}</td>
                                    <td>{fact.p250}</td>
                                    <td>
                                        <Button
                                            onClick={() => setFacts([...facts.slice(0, index), ...facts.slice(index + 1, facts.length)])}
                                        >
                                            Remove
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </Col>

                <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%", maxWidth: "150px", minWidth: "150px" }}
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ProductInfo;
