import axios from '../../config/axios';

//importing components
import { useEffect } from 'react';
import { useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import ManageProduct from '../../Components/Products/ManageProductComponents/ManageProduct';
import Loader from '../../Components/Loader/Loader';

const EditProduct = (props) => {

    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);

    // get product id from props params
    const productId = props.match.params.productID;

    useEffect(() => {
        axios.get(`/admin/products/${productId}`, { withCredentials: true }).then(response => {
            setProduct(response.data);
            setLoading(false);
        });
    }, [productId]);

    return (
        <Layout>
            {loading ?
                <div className="card">
                    <Loader height={'50vh'} />
                </div>
                :
                <ManageProduct product={product} />
            }
        </Layout>
    );
};

export default EditProduct;