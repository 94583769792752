import React, { useRef, useState } from "react";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { exampleData } from "./data";
import Layout from "../../Components/Layout/Layout";
import styles from './GlobalData.module.scss';
import { Button, Col, Input, message, Popconfirm, Row, Tabs, Tag } from "antd";
import axios from '../../config/axios';
import { TbCameraPlus } from "react-icons/tb";
import axiosInstance from "../../config/axios";
import { useEffect } from "react";
import moment from '../../config/moment';

const GlobalData = () => {

    const [zipCodes, setZipCodes] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(`/public/fundamentals/df-zip-codes`).then(response => {
            setZipCodes(response.data);
        })
    }, [refresh]);

    const deleteZipCode = (id) => {
        axios.delete(`/admin/fundamentals/df-zip-codes/${id}`, {withCredentials: true}).then(response=>{
            message.success('Zip Code deleted successfully!');
            setRefresh(Math.random());
        })
    }

    const createZipCode = () =>{
        if(!zipCode || zipCode.toString().length < 5) {
            message.error('Please enter a valid zip code!');
            return;
        }

        setLoading(true);

        let payload = {
            code: zipCode
        }

        axios.post(`/admin/fundamentals/df-zip-codes`, payload,{withCredentials: true}).then(response=>{
            message.success('Zip Code created successfully!');
            setZipCode('');
            setRefresh(Math.random());
        }).then(err=>{
            message.error(err.response.data.msg);
        }).finally(()=>{
            setLoading(false);
        })
    }

    return (
        <Layout>
            <div className={'card'}>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Desi Fresh Delivery Zones" key="1">
                        <Row gutter={[20, 20]}>
                            <Col span={12}>
                                <h2>Add New Zipcode</h2>
                                <br/>
                                <Input
                                    placeholder={'Enter Zip Code'}
                                    type={'number'}
                                    size={'large'}
                                    value={zipCode}
                                    onChange={e=>setZipCode(e.target.value)}
                                />
                                <br/>
                                <br/>
                                <Button
                                    type={'primary'}
                                    size={'large'}
                                    onClick={createZipCode}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Add Zip Code
                                </Button>
                            </Col>
                            <Col span={12}>
                                <h2>All Zip Codes</h2>
                                <br/>
                                <table className={'table'} style={{marginTop: 0}}>
                                    <tr>
                                        <th>Code</th>
                                        <th>Actions</th>
                                    </tr>
                                    {
                                        zipCodes.map((zipCode, index) => {
                                            return <tr key={index}>
                                                <td>{zipCode.code}</td>
                                                {/*<td>{moment(zipCode.created_at).format('DD, MMM YYYY - LT')}</td>*/}
                                                <td>
                                                    <Popconfirm
                                                        title="Are you sure to delete this zip code?"
                                                        onConfirm={() => deleteZipCode(zipCode.id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            danger={true}
                                                            style={{width: '80px'}}
                                                            type={'primary'}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Popconfirm>

                                                </td>
                                            </tr>
                                        })
                                    }
                                </table>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Layout>
    )
}

export default GlobalData;

const OldSiteData = () => {

    const uploadInput2 = useRef(null);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const [siteData, setSiteData] = useState(null);
    const [updatedSiteData, setUpdatedSiteData] = useState();


    useEffect(() => {
        axios.get('/admin/fundamentals/get-global-data').then(response => {
            setSiteData(JSON.parse(response.data.site_data));
            setUpdatedSiteData(JSON.parse(response.data.site_data));
        })
    }, []);


    const handleChange = e => setUpdatedSiteData(e);

    //defining imageUploadHandler function
    const imageUploadHandler = async (e, type) => {

        // get files from event
        const files = e.target.files;

        // running loop over files
        for (const file of files) {
            console.log(file.size * 0.001);
            if ((file.size * 0.001) > 800) {
                message.error('File size cannot exceed 800 KB!');
                return;
            }
            let formData = new FormData();
            formData.append("file", file);

            // request to server here
            try {
                setLoading(true);
                const response = await axios.post(`/admin/fundamentals/upload-file`, formData,
                    {
                        withCredentials: true,
                        params: {
                            type: type
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentage = Math.round(
                                (progressEvent.loaded / progressEvent.total) * 100
                            );
                            setUploadPercentage(percentage);
                        },
                    });

                setImage(response.data.secure_url);

            } catch (error) {
                message.error('A server side error occurred!');
            } finally {
                setLoading(false);
            }
        }
    }

    const saveSettings = () => {
        // console.log(JSON.stringify(updatedSettings));
        let payload = {
            site_data: JSON.stringify(updatedSiteData)
        }
        axiosInstance.post('/admin/fundamentals/update-global-data', payload, {withCredentials: true}).then(response => {
            message.success('Global Data Saved Successfully!');
        })
    }

    return (
        <div>
            <div>
                <div className={styles.input_file_wrapper} style={{marginBottom: '4rem'}}>
                    <div className={styles.input_file_and_files}>
                        <div className={styles.input_file}>
                            <input
                                ref={uploadInput2}
                                type="file"
                                name="file"
                                hidden="hidden"
                                multiple={false}
                                onChange={(e) => {
                                    console.log(e.target.files);
                                    imageUploadHandler(e, "image")
                                }}
                            />
                            <div
                                className={styles.upload_img}
                                onClick={() => {
                                    uploadInput2.current.click();
                                }}
                            >
                                <TbCameraPlus size={50}/>
                                <span>Upload Image</span>
                            </div>
                        </div>

                        {
                            image &&
                            <div style={{marginLeft: '2rem', display: 'flex', flexDirection: 'column'}}>
                                <img src={image} alt="" width={'150px'} style={{marginBottom: '1rem'}}/>
                                <Tag onClick={() => {
                                    navigator.clipboard.writeText(image);
                                    message.success('Image Link Copied!');
                                }} style={{cursor: 'pointer'}} color="green">Copy Image URL</Tag>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                siteData &&
                <Editor value={siteData} onChange={handleChange}/>
            }
            <Button onClick={saveSettings} type="primary" style={{marginTop: '2rem', marginBottom: '5rem'}}>Save
                Setting</Button>
        </div>
    )
}