import { Form, Row, Col, Input, Button, message } from 'antd';
import { useState } from 'react';
import axios from '../../../config/axios';



const ProductKeywords = ({ product }) => {
    const [form] = Form.useForm();

    // states
    const [loading, setLoading] = useState(false);

    // defining updateKeywords function
    const updateKeywords = async (values) => {
        console.log(values);
        setLoading(true);
        await axios.patch(`/admin/products/${product.id}`, values, { withCredentials: true })
        message.success("Keywords are updated successfully")
        setLoading(false);
    }

    return (
        <Form
            form={form}
            onFinish={updateKeywords}
            layout='horizontal'
            labelCol={{
                span: 4,
            }}
            initialValues={
                product && {
                    ...product
                }
            }
        >
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <Form.Item
                        label="Search Words"
                        name="search_words"
                        rules={[
                            {
                                required: true,
                                message: 'Please input search words(comma separated)!',
                            },
                        ]}
                    >
                        <Input placeholder='Maggie' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Keywords"
                        name="keywords"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Keywords(comma separated)!',
                            },
                        ]}
                    >
                        <Input placeholder='Noodles, ready to eat, Indian noodles' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Target audience"
                        name="target_audience"
                        rules={[
                            {
                                required: true,
                                message: 'Please input target audience(comma separated)!',
                            },
                        ]}
                    >
                        <Input placeholder='Teenagers, Indian, indian Students' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="SEO keywords"
                        name="seo_keywords"
                        rules={[
                            {
                                required: true,
                                message: 'Please input seo keywords(comma separated)!',
                            },
                        ]}
                    >
                        <Input placeholder='Noodles, ready to eat, Indian noodles' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Metadata"
                        name="metadata"
                        rules={[
                            {
                                required: true,
                                message: 'Please input metadata!',
                            },
                        ]}
                    >
                        <Input.TextArea autoSize placeholder='This is product metadata' />
                    </Form.Item>
                </Col>

                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                        <Button
                            style={{ width: '100%', maxWidth: '150px', minWidth: '150px' }}
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Col>
            </Row>



        </Form>
    );
}

export default ProductKeywords;