export function sortArrayOfObjectsAlphabetically(array, key) {
  return array.sort((a, b) => {
    const valueA = a[key].toLowerCase();
    const valueB = b[key].toLowerCase();

    if (valueA < valueB) {
      return -1;
    } else if (valueA > valueB) {
      return 1;
    } else {
      return 0;
    }
  });
}



export const formattedAmount = (x) => {
    if(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return '0';
    }
};