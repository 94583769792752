import axios from '../../../config/axios';
import styles from '../ManageProductStyles/Styles.module.scss';

//importing components
import { useHistory } from 'react-router-dom';
import { Form, Row, Col, Select, Input, Button, Tag, message } from 'antd';
import { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { HiPlusCircle } from 'react-icons/hi';
import { sortArrayOfObjectsAlphabetically } from "../../../utils/helpers";
import ProductVariant from "./ProductVariant";

const ProductDetails = ({product}) => {
    const [form] = Form.useForm();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [manus, setManus] = useState([]);
    const [brands, setBrands] = useState([]);
    const [storedBrands, setStoredBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [storedSubCategories, setStoredSubCategories] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [storedSubSubCategories, setStoredSubSubCategories] = useState([]);

    const [variants, setVariants] = useState([]);
    const [variantType, setVariantType] = useState('');
    const [variantValue, setVariantValue] = useState('');
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetch('/germany.json')
            .then((response) => response.json())
            .then((json) => {
                setLocations(json);
            });
    }, []);


    useEffect(() => {


        axios.get('/admin/products', {
            withCredentials: true, params: {
                page: 1,
                limit: 1000
            }
        }).then(response => {
            setProducts(response.data.results);
        })

        axios.get('/public/brands').then(response => {
            let sorted_brands = sortArrayOfObjectsAlphabetically(response.data, 'name');
            setBrands(sorted_brands);
            setStoredBrands(sorted_brands);
        });

        axios.get('/public/manus').then(response => {
            setManus(sortArrayOfObjectsAlphabetically(response.data, 'name'));
        });

        axios.get('/public/categories').then(response => {
            setCategories(sortArrayOfObjectsAlphabetically(response.data, 'name'));
        });

        axios.get('/public/subcategories').then(response => {
            let sorted_sub_cats = sortArrayOfObjectsAlphabetically(response.data, 'name');
            setSubCategories(sorted_sub_cats);
            setStoredSubCategories(sorted_sub_cats);
        });

        axios.get('/public/sub-subcategories').then(response => {
            let sorted_sub_sub_cats = sortArrayOfObjectsAlphabetically(response.data, 'name');
            setSubSubCategories(sorted_sub_sub_cats);
            setStoredSubSubCategories(sorted_sub_sub_cats);
        });

        if (product) {
            setVariants(product.variants);
        }
    }, [product]);

    //push new variant to variant array and reset fields
    const addVariant = () => {
        if (!variantType) {
            message.error('Variant Type is required!');
            return;
        }

        if (!variantValue) {
            message.error('Variant Value is required!');
            return;
        }

        let newVariant = {
            type: variantType,
            value: variantValue
        }
        setVariants(variants => variants.concat(newVariant));
        setVariantType('');
        setVariantValue('');
    }

    //calling create product endpoint
    const onFinishHandler = (values) => {

        if (variants.length === 0) {
            message.error('Please add at least variant!');
        }

        setLoading(true);

        values.variants = variants;
        values.fbt = values.fbt ? values.fbt.join() : null;
        values.cab = values.cab ? values.cab.join() : null;
        values.rp = values.rp ? values.rp.join() : null;

        if (product) {
            axios.patch(`/admin/products/${product.id}`, values, {withCredentials: true}).then(response => {
                message.success('Product Updated Successfully!');


                axios.patch(`/admin/products/variants/${product.id}`, {variants: variants}, {withCredentials: true}).then(responseV2 => {
                    setLoading(false);
                    window.location.reload();
                })
            }).catch(error => {
                setLoading(false);
            });
        } else {
            axios.post('/admin/products', values, {withCredentials: true}).then(response => {
                message.success('Product Created Successfully!');
                history.push(`/products/edit/${response.data.product_id}`);
            }).catch(error => {
                setLoading(false);
            });
        }
    }


    return (
        <Form
            form={form}
            onFinish={onFinishHandler}
            layout={{
                labelCol: {span: 8},
                wrapperCol: {span: 16}
            }}
            initialValues={product &&
                {
                    ...product,
                    category: product.category.id,
                    sub_category: product.sub_category.id,
                    product_id: product.id,
                    fbt: product.fbt ? product.fbt.split(',') : [],
                    cab: product.cab ? product.cab.split(',') : [],
                    rp: product.rp ? product.rp.split(',') : [],
                    brand: product?.brand?.id
                }}
        >
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Form.Item
                        name="category"
                        label="Category"
                        rules={[{required: true, message: "Select a category"}]}
                        required
                    >
                        <Select
                            placeholder="Select a Category"
                            onChange={value => {
                                setSubCategories(storedSubCategories.filter(subCat => subCat.category === value))
                            }}
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                categories.map((category, index) => {
                                    return <Select.Option key={index}
                                                          value={category.id}>{category.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="sub_category"
                        label="Sub Category"
                        rules={[{required: true, message: "Select a Sub Category"}]}
                        required
                    >
                        <Select
                            placeholder="Select a Sub Category"
                            onChange={value => {
                                setSubSubCategories(storedSubSubCategories.filter(subSubCat => subSubCat.subcategory === value))
                            }}
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                subCategories.map((subCategory, index) => {
                                    return <Select.Option key={index}
                                                          value={subCategory.id}>{subCategory.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="sub_subcategory"
                        label="Sub SubCategory"
                        rules={[{required: false, message: "Select a Sub Sub Category"}]}
                    >
                        <Select
                            placeholder="Select a SubSubCategory"
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                subSubCategories.map((subSubCategory, index) => {
                                    return <Select.Option key={index}
                                                          value={subSubCategory.id}>{subSubCategory.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="product_id"
                        label="Product ID"
                        rules={[{required: false}]}
                    >
                        <Input type={'text'} disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="product_id_type"
                        label=""
                        rules={[{required: false}]}
                    >
                        <Select
                            disabled
                            placeholder="EAN"
                        >
                            <Select.Option value="EAN">EAN</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="title"
                        label="Product Title"
                        rules={[{required: true, message: "Product Title is required"}]}
                        required
                    >
                        <Input type={'text'} maxLength={255}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="manufacturer"
                        label="Manufacturer"
                        rules={[{required: true, message: "Manufacturer is required"}]}
                        required
                    >
                        <Select
                            placeholder="Select a Manufacturer"
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={value => {
                                // console.log(value, storedBrands[0].manufacturer.id)
                                // storedBrands.map(brand=>{
                                //     console.log(brand.manufacturer);
                                // })
                                setBrands(storedBrands.filter(brand => brand.manufacturer?.id === value))
                            }}
                        >
                            {
                                manus.map((manu, index) => {
                                    return <Select.Option key={index}
                                                          value={manu.id}>{manu.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="brand"
                        label="Brand"
                        rules={[{required: true, message: "Brand Name is required"}]}
                        required
                    >
                        <Select
                            placeholder="Select a Brand"
                            showSearch={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                brands.map((brand, index) => {
                                    return <Select.Option key={index}
                                                          value={brand.id}>{brand.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="package_type"
                        label="Package Type"
                        rules={[{required: false, message: "Package Type is required"}]}
                    >
                        <Input type={'text'} maxLength={255}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="available_locations"
                        label="Available Locations"
                    >
                        <Select
                            showSearch={true}
                            mode={'multiple'}
                        >
                            {
                                locations.map((location, index) => {
                                    return <Select.Option value={location.zip}
                                                          key={index}>{location.city} - {location.zip}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <div className={styles.formItem}>
                        <label htmlFor="">Variant Type: </label>
                        <Select
                            value={variantType}
                            onChange={value => setVariantType(value)}
                            style={{width: '100%'}}

                        >
                            <Select.Option value={'weight_g'}>Weight - G</Select.Option>
                            <Select.Option value={'weight_kg'}>Weight - KG</Select.Option>
                            <Select.Option value={'volume_ml'}>Volume - ML</Select.Option>
                            <Select.Option value={'volume_l'}>Volume - L</Select.Option>
                        </Select>
                    </div>
                </Col>
                <Col span={10}>
                    <div className={styles.formItem}>
                        <label htmlFor="">Variant Value: </label>
                        <Input
                            onChange={e => setVariantValue(e.target.value)}
                            value={variantValue}
                        />
                    </div>
                </Col>
                <Col span={4}>
                    <Button type='text' onClick={addVariant} style={{alignItems: 'center', display: 'flex'}}>
                        <HiPlusCircle size={20} style={{marginRight: '.5rem'}} color={'var(--color-primary)'}/>
                        Add Variant
                    </Button>
                </Col>
                {
                    variants.length > 0 &&
                    <Col span={24} style={{marginTop: '2rem'}}>
                        <div className={styles.formItem}>
                            <label htmlFor="">Added Variants: </label>
                            {/*{*/}
                            {/*    variants.map((variant, index) => {*/}
                            {/*        return <Tag*/}
                            {/*            key={index} color='var(--color-primary)'*/}
                            {/*            onClick={() => {*/}
                            {/*                setVariants(variants.filter(currentVariant => currentVariant.value !== variant.value));*/}
                            {/*                console.log(variant.id);*/}
                            {/*                axios.delete(`/admin/products/variants/mod/${variant.id}`, {withCredentials: true}).then(response => {*/}
                            {/*                    message.success('Variant Deleted Successfully!');*/}
                            {/*                })*/}
                            {/*            }}*/}
                            {/*            style={{cursor: 'pointer'}}*/}
                            {/*        >*/}
                            {/*            <div style={{display: 'flex', alignItems: 'center'}}>*/}
                            {/*                {variant.type} - {variant.value}*/}
                            {/*                <IoIosClose size={20}/>*/}
                            {/*            </div>*/}
                            {/*        </Tag>*/}
                            {/*    })*/}
                            {/*}*/}
                            <table className={'table'}>
                                <tbody>
                                <tr>
                                    <th>Variant Type</th>
                                    <th>Variant Value</th>
                                    <th>Actions</th>
                                </tr>
                                {
                                    variants.map((variant) => {
                                        return <ProductVariant variants={variants} setVariants={setVariants} variant={variant} key={variant.id} />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                }

                {
                    products.length > 0 &&
                    <>
                        <Col span={24}>
                            <Form.Item
                                name='fbt'
                                label="Frequently Bought Together"
                            >
                                <Select
                                    mode='multiple'
                                >
                                    {
                                        products.map((prod, index) => {
                                            return <Select.Option key={index}
                                                                  value={`${prod.id}`}>{prod.title} ({prod.seller_sku})</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='cab'
                                label="Customers Also Buy"
                            >
                                <Select
                                    mode='multiple'
                                >
                                    {
                                        products.map((prod, index) => {
                                            return <Select.Option key={index}
                                                                  value={`${prod.id}`}>{prod.title} ({prod.seller_sku})</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='rp'
                                label="Recommended Products"
                            >
                                <Select
                                    mode='multiple'
                                >
                                    {
                                        products.map((prod, index) => {
                                            return <Select.Option key={index}
                                                                  value={`${prod.id}`}>{prod.title} ({prod.seller_sku})</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                    </>
                }
                <Col span={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Form.Item>
                        <Button
                            style={{width: '100%', maxWidth: '150px', minWidth: '150px'}}
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                            disabled={loading}
                        >
                            {
                                product ?
                                    "Update" :
                                    "Save"
                            }
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ProductDetails;