import { useState, useEffect, useRef } from 'react';
import styles from "../ManageProductStyles/Styles.module.scss";
import { TbCameraPlus } from "react-icons/tb";
import { AiOutlineVideoCameraAdd } from "react-icons/ai"
import axios from '../../../config/axios';
import { message, Button, Select } from 'antd';
import ReorderImages from '../../ReorderImages/ReorderImages';

const ProductImages = ({ product }) => {

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState(0);

    console.log(images);

    const uploadInput = useRef(null);
    const uploadInput2 = useRef(null);
    const uploadInput3 = useRef(null);

    useEffect(() => {
        if (product.variants[selectedVariant]?.images) {
            setImages(product.variants[selectedVariant]?.images)
        } else {
            setImages([])
        }
    }, [product, selectedVariant]);

    //defining imageUploadHandler function
    const imageUploadHandler = async (e, type) => {

        // get files from event
        const files = e.target.files;

        // running loop over files
        for (const file of files) {
            console.log(file.size * 0.001);
            if ((file.size * 0.001) > 300) {
                message.error('File size cannot exceed 300 KB!');
                return;
            }
            let formData = new FormData();
            formData.append("file", file);

            // request to server here
            try {
                setLoading(true);
                const response = await axios.post(`/admin/fundamentals/upload-file`, formData,
                    {
                        withCredentials: true,
                        params: {
                            type: type
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentage = Math.round(
                                (progressEvent.loaded / progressEvent.total) * 100
                            );
                            setUploadPercentage(percentage);
                        },
                    });

                setImages((images) => images.concat(response.data.secure_url));

            } catch (error) {
                message.error('A server side error occurred!');
            } finally {
                setLoading(false);
            }
        }
    }

    // update image reordering
    const updateImages = (images) => {
        setImages(images);
    };


    // defining UpdateProductImage function
    const UpdateProductImage = async () => {
        setUpdateLoading(true)
        let variants = product.variants;
        variants[selectedVariant].images = images.length > 0 ? images : null;
        await axios.patch(`/admin/products/variants/${product.id}`, { variants: variants }, { withCredentials: true })
        message.success('Image updated successfully')
        setUpdateLoading(false)
    }

    return (
        <>
            <strong style={{ display: 'block' }}>Images and videos can be uploaded from here. The image  and video specifications can be found <a href="/">here</a> </strong>
            <br />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <label style={{ marginBottom: 0, fontWeight: 'bold', marginRight: '5px' }} htmlFor="">Change Variant: </label>
                <Select
                    value={selectedVariant}
                    onChange={value => {
                        setSelectedVariant(value)
                    }}
                >
                    {
                        product.variants.map((variant, index) => {
                            return <Select.Option value={index}>{variant.value}</Select.Option>
                        })
                    }
                </Select>

            </div>
            <div className={styles.image_upload_container}>
                {/* <div className={styles.input_file_wrapper}>
                    <div className={styles.input_file_and_files}>
                        <div className={styles.input_file}>
                            <input
                                ref={uploadInput}
                                type="file"
                                name="file"
                                hidden="hidden"
                                multiple={false}
                                onChange={(e) => {
                                    imageUploadHandler(e, "image")
                                }}
                            />
                            <div
                                className={styles.upload_img}
                                onClick={() => {
                                    uploadInput.current.click();
                                }}
                            >
                                <TbCameraPlus size={50} />
                                <span>Upload</span>
                            </div>
                        </div>
                        <span className={styles.upload_label_bottom}>Main Image</span>
                    </div>
                </div> */}

                <div className={styles.input_file_wrapper}>
                    <div className={styles.input_file_and_files}>
                        <div className={styles.input_file}>
                            <input
                                ref={uploadInput2}
                                type="file"
                                name="file"
                                hidden="hidden"
                                multiple={true}
                                onChange={(e) => {
                                    console.log(e.target.files);
                                    imageUploadHandler(e, "image")
                                }}
                            />
                            <div
                                className={styles.upload_img}
                                onClick={() => {
                                    uploadInput2.current.click();
                                }}
                            >
                                <TbCameraPlus size={50} />
                                <span>Upload</span>
                            </div>
                        </div>
                        <span className={styles.upload_label_bottom}>Product Images</span>
                    </div>
                </div>

                <div className={styles.input_file_wrapper}>
                    <div className={styles.input_file_and_files}>
                        <div className={styles.input_file}>
                            <input
                                ref={uploadInput3}
                                type="file"
                                name="file"
                                hidden="hidden"
                                accept=" video/*"
                                multiple={true}
                                onChange={(e) => {
                                    imageUploadHandler(e, "video")
                                }}
                            />
                            <div
                                className={styles.upload_img}
                                onClick={() => {
                                    uploadInput3.current.click();
                                }}
                            >
                                <AiOutlineVideoCameraAdd size={50} />
                                <span>Upload</span>
                            </div>
                        </div>
                        <span className={styles.upload_label_bottom}>Product Videos</span>
                    </div>
                </div>
            </div>

            {images.length > 0 && (
                <div className={styles.uploaded_images_and_videos}>
                    <h2 className='primary_h2'> Images {loading && `: Uploading... ${uploadPercentage}%`} </h2>
                    <span>Drag Images to Reorder (The first image is the main/featured image)</span>
                    <div className={styles.uploaded_urls}>
                        <ReorderImages images={images} callback={updateImages} />
                    </div>
                </div>
            )}


            <div className={styles.submit_images}>
                <Button
                    style={{ maxWidth: '150px', minWidth: '150px' }}
                    type='primary'
                    htmlType='submit'
                    loading={updateLoading}
                    disabled={updateLoading}
                    onClick={UpdateProductImage}
                >
                    Update
                </Button>
            </div>
        </>
    );
}

export default ProductImages;