import styles from './Layout.module.scss';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

//importing components
import { message } from 'antd';

// importing icons
import { ImStatsBars } from 'react-icons/im';
import { BiLogOutCircle, BiCategory, BiCategoryAlt } from 'react-icons/bi';
import { GiSwapBag } from "react-icons/gi";
import { MdOutlineCategory } from "react-icons/md";
import { HiOutlineUserCircle } from 'react-icons/hi';
import { BsCart4, BsMailbox } from 'react-icons/bs';


//importing context
import { AuthContext } from '../../Contexts/AuthContext';
import { AiFillMail, AiOutlineDatabase } from 'react-icons/ai';


const Layout = (props) => {

    const location = useLocation();
    const {user, setUser, setIsAuthenticated} = useContext(AuthContext);

    //defining signOutHandler function
    const signOutHandler = () => {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('device_id');
        message.success('Signed Out Successfully!')
        props.history.push('/login');
    }


    return (
        <div className={styles.main}>
            <header className={styles.header}>
                <div>
                    <img className={styles.logo} src="/logo.svg" alt="logo"/>
                </div>
                <div className={styles.user}>
                    <img className={styles.avatar} src={user.dp ? user.dp : "/avatar.png"} alt=""/>
                    <div className={styles.user__text}>
                        <span style={{fontSize: '1.2rem'}}>Welcome,</span>
                        <span className={styles.name}> {user.first_name} {user.last_name}</span>
                    </div>
                </div>
            </header>
            <nav className={styles.sidebar}>
                <ul>
                    <Link to={'/'}>
                        <li className={location.pathname === '/' ? styles.selected : null}>
                            <ImStatsBars size={20}/>
                            <span>Home</span>
                        </li>
                    </Link>
                    <Link to={'/orders'}>
                        <li className={location.pathname === '/orders' ? styles.selected : null}>
                            <BsCart4 size={20}/>
                            <span>Orders</span>
                        </li>
                    </Link>
                    <Link to={'/users'}>
                        <li className={location.pathname === '/users' ? styles.selected : null}>
                            <HiOutlineUserCircle size={20}/>
                            <span>Users</span>
                        </li>
                    </Link>
                    <Link to={'/categories'}>
                        <li className={location.pathname === '/categories' ? styles.selected : null}>
                            <BiCategory size={20}/>
                            <span>Categories</span>
                        </li>
                    </Link>
                    <Link to={'/sub-categories'}>
                        <li className={location.pathname === '/sub-categories' ? styles.selected : null}>
                            <BiCategoryAlt size={20}/>
                            <span>Sub Categories</span>
                        </li>
                    </Link>
                    <Link to={'/sub-subcategories'}>
                        <li className={location.pathname === '/sub-subcategories' ? styles.selected : null}>
                            <MdOutlineCategory size={20}/>
                            <span>Sub Sub Categories</span>
                        </li>
                    </Link>
                    <Link to={'/manufacturers'}>
                        <li className={location.pathname === '/manufacturers' ? styles.selected : null}>
                            <BiCategory size={20}/>
                            <span>Manufacturers</span>
                        </li>
                    </Link>
                    <Link to={'/brands'}>
                        <li className={location.pathname === '/brands' ? styles.selected : null}>
                            <BiCategory size={20}/>
                            <span>Brands</span>
                        </li>
                    </Link>
                    <Link to={'/products'}>
                        <li className={location.pathname.includes('/products') ? styles.selected : null}>
                            <GiSwapBag size={20}/>
                            <span>Products</span>
                        </li>
                    </Link>
                    <Link to={'/contact-us'}>
                        <li className={location.pathname === '/contact-us' ? styles.selected : null}>
                            <BsMailbox size={20}/>
                            <span>Contact Us Entries</span>
                        </li>
                    </Link>
                    <Link to={'/newsletter'}>
                        <li className={location.pathname === '/newsletter' ? styles.selected : null}>
                            <AiFillMail size={20}/>
                            <span>Newsletter</span>
                        </li>
                    </Link>
                    <Link to={'/global-data'}>
                        <li className={location.pathname === '/global-data' ? styles.selected : null}>
                            <AiOutlineDatabase size={20}/>
                            <span>Config</span>
                        </li>
                    </Link>

                    {/* super user specific routes */}
                    {/* {
                        user.is_superuser &&
                        <>
                            <Link to={'/reports'}>
                                <li className={location.pathname === '/reports' ? styles.selected:null}>
                                    <GoReport size={20} />
                                    <span>Reports</span>
                                </li>
                            </Link>
                        </>
                    } */}


                    <li
                        className={styles.logout}
                        onClick={signOutHandler}
                    >
                        <BiLogOutCircle size={20} color='var(--color-red)'/>
                        <span>Sign Out</span>
                    </li>

                </ul>
            </nav>
            <div className={styles.main__body}>
                {props.children}
            </div>
        </div>
    );
};

export default Layout;