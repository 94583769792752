import { Button, Input, message } from 'antd';
import React from 'react';
import axios from '../../config/axios';
import { Select } from 'antd';
import { useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';


const UserItem = ({ user }) => {

    const [rp, setRP] = useState(user.reward_points);

    const updateUser = (role, userID) => {
        axios.patch(`/admin/users/${userID}`, { role: role }, { withCredentials: true }).then(response => {
            message.success('User updated successfully!');
        })
    }

    const updateRP = () => {
        axios.patch(`/admin/users/${user.id}`, { reward_points: parseFloat(rp).toFixed(2) }, { withCredentials: true }).then(response => {
            message.success('Reward Points updated successfully!');
        })
    }


    return (
        <tr>
            <td>{user.id}</td>
            <td>{user.mr} {user.first_name} {user.last_name}</td>
            <td>{user.email}</td>
            <td>
                {
                    user.reward_card_number ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Input
                                value={rp}
                                style={{ maxWidth: '100px' }}
                                onChange={e => setRP(e.target.value)}
                            />
                            <Button style={{ marginLeft: '1rem' }} type="primary" onClick={updateRP}>
                                <AiOutlineSave size={20} />
                            </Button>
                        </div>
                        :
                        <span>Not Enrolled</span>
                }
            </td>
            <td>
                <Select
                    defaultValue={user.role}
                    onChange={value => updateUser(value, user.id)}
                >
                    <Select.Option value='admin'>Admin</Select.Option>
                    <Select.Option value='customer'>Customer</Select.Option>
                </Select>
            </td>
            <td>
                N/A
            </td>
        </tr>
    );
};

export default UserItem;