import { message, Select, Tabs } from "antd";
import axios from "../../../config/axios";
import ProductDescription from "./ProductDescription";
import ProductDetails from "./ProductDetails";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";
import ProductKeywords from "./ProductKeywords";
import ProductPrice from "./ProductPrice";

const ManageProduct = ({ product }) => {
    const changeProductStatus = (status) => {
        axios.patch(`/admin/products/${product.id}`, { status: status }, { withCredentials: true }).then((response) => {
            message.success(`Status changed to ${status}`);
        });
    };

    return (
        <div className="card" style={{ position: "relative" }}>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Details" key="1">
                    <ProductDetails product={product} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Variations" key="2" disabled={!product}>
                    Content of Variations
                </Tabs.TabPane> */}
                <Tabs.TabPane tab="Price" key="3" disabled={!product}>
                    <ProductPrice product={product} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Transfers" key="4" disabled={!product}>
                    Content of Transfers
                </Tabs.TabPane> */}

                <Tabs.TabPane tab="Media" key="6" disabled={!product}>
                    <ProductImages product={product} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Description" key="7" disabled={!product}>
                    <ProductDescription product={product} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Info" key="5" disabled={!product}>
                    <ProductInfo product={product} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Keywords" key="8" disabled={!product}>
                    <ProductKeywords product={product} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="More details...." key="9" disabled={!product}>
                    Content of More details....
                </Tabs.TabPane> */}
            </Tabs>

            {product && (
                <div style={{ position: "absolute", top: "2%", right: "2%" }}>
                    <a href={`https://desifresh.co/products/${product.slug}`} target={"_blank"} rel="noreferrer">
                        Preview
                    </a>
                    <Select defaultValue={product.status} bordered={false} onChange={(value) => changeProductStatus(value)}>
                        <Select.Option value={"published"}>Published</Select.Option>
                        <Select.Option value={"draft"}>Draft</Select.Option>
                    </Select>
                </div>
            )}
        </div>
    );
};

export default ManageProduct;
