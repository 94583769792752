import { useEffect, useState } from "react";
import axios from '../../config/axios';
import moment from '../../config/moment';

//importing components
import Layout from "../../Components/Layout/Layout"
import { Pagination, Input, Button, Select, Checkbox, message, Tag, DatePicker } from "antd";
import Loader from "../../Components/Loader/Loader";
import { Row, Col, Form } from 'antd';
import { SiMicrosoftexcel } from 'react-icons/si';
import OrderItem from "../../Components/OrderItem/OrderItem";
import { order_status } from "../../utils/static";

const Orders = () => {

    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();

    const [limit, setLimit] = useState(20);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);

    const [filters, setFilters] = useState({});
    const [refresh, setRefresh] = useState(null);

    useEffect(() => {
        setLoading(true);
        console.log(filters)
        window.scrollTo(0, 0);
        axios.get('/admin/orders', {
            withCredentials: true, params: {
                page: page,
                limit: limit,
                order_id: filters.order_id ? filters.order_id : null,
                name: filters.name ? filters.name : null,
                phone_number: filters.phone_number ? filters.phone_number : null,
                status: filters.status ? filters.status : null,
                payment_status: filters.payment_status ? filters.payment_status : null,
                email: filters.email ? filters.email : null,
                start: filters.range ? moment(filters.range[0]).format('YYYY-MM-DD') : null,
                end: filters.range ? moment(filters.range[1]).format('YYYY-MM-DD') : null,
            }
        }).then(response => {
            setOrders(response.data.results);
            setDataCount(response.data.count);
            setLoading(false);
        })
    }, [page, filters, limit, refresh]);


    //defining applyFilter function
    const applyFilter = (values) => {
        setFilters(values);
    }

    const updateUser = (role, userID) => {
        axios.patch(`/admin/users/${userID}`, { role: role }, { withCredentials: true }).then(response => {
            message.success('User updated successfully!');
        })
    }

    return (
        <Layout>
            <h1 className="title">Order Management</h1>
            <div className="card">
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <span className="card__title">All Orders</span>
                    {/* <a href="/excel/user" target={'_blank'} rel='noreferrer' style={{ display: 'flex', alignItems: 'center' }}>
                        <SiMicrosoftexcel size={20} style={{ marginRight: '.5rem' }} />
                        <span style={{ color: 'var(--color-primary)', cursor: 'pointer' }} className="card__title">Export To Excel</span>
                    </a> */}
                </div>

                 <div className="filter">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={applyFilter}
                    >
                        <Row gutter={[16, 16]}>
                            <Col sm={4}>
                                <Form.Item label="Order ID" name={'order_id'}>
                                    <Input placeholder="Filter by order ID" />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Order Status" name={'status'}>
                                    <Select
                                    placeholder={'Filter by Status'}
                                    >
                                        {
                                            order_status.map((status, index)=>{
                                                return <Select.Option key={index} value={status.value}>{status.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Phone Number" name={'phone_number'}>
                                    <Input placeholder="Filter by phone number" />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Email" name={'email'}>
                                    <Input placeholder="Filter by email" type={'email'} />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Payment Status" name={'payment_status'}>
                                    <Select
                                        placeholder="Filter by Payment Status"
                                    >
                                        <Select.Option value={'paid'}>Paid</Select.Option>
                                        <Select.Option value={'pending'}>Pending/Due</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Date Range" name={'range'}>
                                    <RangePicker />
                                </Form.Item>
                            </Col>

                            <Col sm={4} style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Form.Item label="Actions">
                                    <Button htmlType="submit" type="primary" style={{ flex: '0 0 48%', marginRight: '.5rem' }}>Apply</Button>
                                    <Button onClick={() => {
                                        form.resetFields();
                                        setFilters({});
                                    }
                                    } type="danger" style={{ flex: '0 0 48%' }}>Clear</Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                    <Row>
                        <Col sm={4}></Col>
                    </Row>
                </div>

                {
                    loading ?
                        <Loader height={'75vh'} />
                        :
                        <>
                            <div className="table__wrapper">
                                <table className="table">
                                    <thead>
                                        <th>ID</th>
                                        <th>Customer</th>
                                        <th>Address</th>
                                        <th>Delivery</th>
                                        <th>Payment</th>
                                        <th>Payment Status</th>
                                        <th>Order Status</th>
                                        <th>Actions</th>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map((order, index) => {
                                                return <OrderItem order={order} key={index} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <span>Showing {((page - 1) * limit) + 1} to {((page - 1) * limit) + limit > dataCount ? dataCount : ((page - 1) * limit) + limit} of {dataCount} entries</span>
                                <Pagination
                                    pageSize={limit}
                                    total={dataCount}
                                    current={page}
                                    onChange={value => setPage(value)}
                                    onShowSizeChange={(curr, size) => setLimit(size)}
                                />
                            </div>
                        </>
                }

            </div>
        </Layout>
    );
}

export default Orders;