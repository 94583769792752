import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from '../../config/axios'
import moment from '../../config/moment';

const Last7DaysOrders = ({orderData}) => {


    const [noOfOrders, setNoOfOrders] = useState([]);
    const [dateList, setDateList] = useState([]);

    useEffect(() => {


        Object.keys(orderData).forEach(data => {
            console.log(data);
            setDateList(dateList => dateList.concat(data));
            setNoOfOrders(noOfOrders => noOfOrders.concat(orderData[data]))
        });

    }, []);

    const data = {


        series: [{
            name: 'No of Orders',
            data: noOfOrders.reverse()
        }],
        options: {
            colors: ['rgba(62,192,116,0.62)'],
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'date',
                categories: dateList.reverse()
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
        },


    };

    return (
        <>
            <ApexCharts options={data.options} series={data.series} width={'100%'} height={450} type="area"/>
        </>
    );
}

export default Last7DaysOrders;