import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Modal, message, Spin, Image, Select } from 'antd';

// importing styles
import styles from './SubSubCategories.module.scss';

// importing components
import ImageUploader from '../../Components/ImageUploader/ImageUploader';
import Layout from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import NoData from '../../Components/NoData/NoData';

// importing axios
import axios from '../../config/axios';

// importing icons
import { FiEdit } from "react-icons/fi";
import { WarningFilled } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";


const SubSubCategories = () => {
    const [refresh, setRefresh] = useState(null);
    return (
        <Layout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <SubSubCategoryAddForm setRefresh={setRefresh} />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllSubSubCategories refresh={refresh} setRefresh={setRefresh} />
                </Col>
            </Row>
        </Layout>
    );
}

export default SubSubCategories;


// sub components for this component

// sub subcategory add form component
const SubSubCategoryAddForm = ({ setRefresh }) => {

    const [form] = Form.useForm();

    const [images, setImages] = useState([]);
    const [addSubSubCategoryLoading, setAddSubSubCategoryLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);

    useEffect(() => {
        // get All Categories From API for select
        const getAllCategories = async () => {
            const res = await axios.get("/public/categories", {
                withCredentials: false,
            });
            setCategories(res.data);
        };
        getAllCategories();
    }, []);


    // get sub categories by category from API for select
    const getSubCategoriesByCategory = async (categoryId) => {
        const res = await axios.get("/public/subcategories", {
            withCredentials: false,
            params: { category: categoryId }
        });
        setSubCategoriesByCategory(res.data);
    }

    const handleAddSubSubCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];

        // request to server here
        try {
            setAddSubSubCategoryLoading(true)
            await axios.post(`/admin/sub-subcategories`, values, { withCredentials: true })
            message.success("Added New Sub SubCategory!");
            form.resetFields();
            setImages([]);
            setRefresh(Math.random());
        } catch (error) {
            message.error(`Can't create sub subcategory right now!`)
        } finally {
            setAddSubSubCategoryLoading(false);
        }
    }

    return (
        <div className="card" style={{ minHeight: '80vh' }}>
            <h2 className="primary_h2"> Add New Sub SubCategory </h2>

            <Form
                name="addNewSubSubCategory"
                form={form}
                onFinish={handleAddSubSubCategory}
                layout="vertical"
                scrollToFirstError
                requiredMark={false}
            >
                <Form.Item
                    label="Sub SubCategory Name"
                    name="name"
                    rules={[{ required: true, message: "Please Add Sub SubCategory Name" }]}
                >
                    <Input placeholder="Fruits" />
                </Form.Item>

                <Form.Item
                    label="Select Category"
                    name="category"
                    rules={[{ required: true, message: "Please Select Category" }]}
                >
                    <Select
                        placeholder="Select a Category"
                        onChange={(value) => {
                            getSubCategoriesByCategory(value)
                        }}
                    >
                        {categories.map((option, index) => (
                            <Select.Option value={option.id} key={index}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Select Sub Category"
                    name="subcategory"
                    rules={[{ required: true, message: "Please Select Sub Category" }]}
                >
                    <Select placeholder="Select a Sub Category">
                        {subCategoriesByCategory.map((option, index) => (
                            <Select.Option value={option.id} key={index}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Sub SubCategory Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: 20 }}
                        loading={addSubSubCategoryLoading}
                    >
                        Add New Sub SubCategory
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

// show all categories component
const ShowAllSubSubCategories = ({ refresh, setRefresh }) => {

    const [subSubcategoriesLoading, setSubSubcategoriesLoading] = useState(true);
    const [subSubcategories, setSubSubcategories] = useState([]);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        // get All Sub SubCategories From API
        const getAllSubSubCategories = async () => {
            setSpin(true);
            const res = await axios.get("/public/sub-subcategories", {
                withCredentials: false,
            });
            setSubSubcategories(res.data);
            setSpin(false);
            setSubSubcategoriesLoading(false);
        };
        getAllSubSubCategories();
    }, [refresh]);

    return (
        <div className="card" style={{ minHeight: '80vh' }}>
            <h2 className="primary_h2">All Sub SubCategories</h2>
            {subSubcategoriesLoading ? (
                <Loader height="100%" />
            ) :
                (
                    <>
                        {subSubcategories.length > 0 ? (
                            <Spin spinning={spin} indicator={<Loader height="100%" />}>
                                <div className='table__wrapper'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subSubcategories.map((subSubcategory, index) => (
                                                <SubSubCategoryItem
                                                    key={index}
                                                    subSubcategory={subSubcategory}
                                                    setRefresh={setRefresh}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: 20 }}>
                                    <span>Total {subSubcategories.length} Sub SubCategories</span>
                                </div>
                            </Spin>
                        ) : (
                            <NoData message="No Sub SubCategories Found" />
                        )}
                    </>
                )
            }
        </div>
    )
}

// category item component
const SubSubCategoryItem = ({ subSubcategory, setRefresh }) => {
    const [showModal, setShowModal] = useState(false);

    // defining deleteCategory function
    const deleteSubSubCategory = async (id) => {
        try {
            await axios.delete(`/admin/sub-subcategories/${id}`, { withCredentials: true });
            message.success("Deleted the Sub Subcategory");
            setRefresh(Math.random());
        } catch (error) {
            message.error("Something went wrong!")
        }
    }

    // show confirm modal component
    const showConfirm = () => {
        Modal.confirm({
            title: `Are You Sure?`,
            icon: <WarningFilled />,
            content: `Once you delete a sub subcategory, this action can't be undone.`,
            okText: 'Yes,Confirm!',

            async onOk() {
                await deleteSubSubCategory(subSubcategory.id);
            },

            onCancel() {
                message.info("No changes occurred!")
            },
        });
    };

    return (
        <>
            <tr>
                <td>
                    <Image height={50} src={subSubcategory.image} alt="logo" />
                </td>
                <td>{subSubcategory.name}</td>
                <td>
                    <div className={styles.action_btn}>
                        <FiEdit
                            size={20}
                            title="Edit Sub SubCategory"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        />
                        <MdDelete
                            size={20}
                            title="Delete Sub SubCategory"
                            onClick={showConfirm}
                        />
                    </div>
                </td>
            </tr>
            {showModal && (
                <SubSubCategoryUpdateModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    subSubcategory={subSubcategory}
                    setRefresh={setRefresh}
                />
            )}
        </>

    )
}

// CategoryUpdateModal component
const SubSubCategoryUpdateModal = ({ showModal, setShowModal, subSubcategory, setRefresh }) => {

    // states
    const [images, setImages] = useState(() => [subSubcategory.image]);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);


    useEffect(() => {
        // get All Categories From API for select
        const getAllCategories = async () => {
            const res = await axios.get("/public/categories", {
                withCredentials: false,
            });
            setCategories(res.data);
        };
        getAllCategories();

    }, []);

    // get sub categories by category from API for select
    const getSubCategoriesByCategory = async (categoryId) => {
        const res = await axios.get("/public/subcategories", {
            withCredentials: false,
            params: { category: categoryId }
        });
        setSubCategoriesByCategory(res.data);
    }

    useEffect(() => {
        getSubCategoriesByCategory(subSubcategory.category);
    }, [subSubcategory.category]);

    // defining handleUpdateCategory function
    const handleUpdateSubSubCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];

        try {
            setUpdateLoading(true);
            await axios.put(`/admin/sub-subcategories/${subSubcategory.id}`, values, { withCredentials: true });
            message.success("Updated the Sub Subcategory");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            setUpdateLoading(false);
            setShowModal(false);
            setRefresh(Math.random());
        }
    }

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setShowModal(false);
            }}
            closeIcon={<RiCloseCircleLine size={20} />}
            width={600}
            centered
            footer={null}
        >
            <h2 className="primary_h2"> Update Sub SubCategory: {subSubcategory.name} </h2>

            <Form
                name="updateSubSubCategory"
                onFinish={handleUpdateSubSubCategory}
                layout="vertical"
                initialValues={{
                    ...subSubcategory,
                }}
            >
                <Form.Item
                    label="Sub SubCategory Name"
                    name="name"
                    rules={[{ required: true, message: "Please Add Sub SubCategory Name" }]}
                >
                    <Input placeholder="Grocery" />
                </Form.Item>

                <Form.Item
                    label="Select Category"
                    name="category"
                    rules={[{ required: true, message: "Please Select Category" }]}
                >
                    <Select
                        placeholder="Select a Category"
                        onChange={(value) => {
                            getSubCategoriesByCategory(value)
                        }}
                    >
                        {categories.map((option, index) => (
                            <Select.Option value={option.id} key={index}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Select Sub Category"
                    name="subcategory"
                    rules={[{ required: true, message: "Please Select Sub Category" }]}
                >
                    <Select placeholder="Select a Sub Category">
                        {subCategoriesByCategory.map((option, index) => (
                            <Select.Option value={option.id} key={index}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Sub SubCategory Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: 20 }}
                        loading={updateLoading}
                    >
                        Update Sub SubCategory
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}