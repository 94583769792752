import { useState } from "react";

// Components
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

const ProductDescriptionEditor = ({ description, setDescription }) => {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(description))));

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="draftjs-editor-wrapper"
            editorClassName="editorClassName"
        />
    );
};

export default ProductDescriptionEditor;
