import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// importing axios
import axios from '../../config/axios';

// importing styles
import styles from './Products.module.scss';

//importing components
import { Button, Pagination, Image, Row, Col, Form, Input, Select } from 'antd';
import Layout from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import NoData from '../../Components/NoData/NoData';
import ProductItem from '../../Components/Products/ProductItem/ProductItem';
import { sortArrayOfObjectsAlphabetically } from "../../utils/helpers";

// importing icons
// import { SiMicrosoftexcel } from 'react-icons/si';

const Products = () => {
    const [form] = Form.useForm();

    const [limit, setLimit] = useState(20);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);

    // filter states
    const [categories, setCategories] = useState([]);
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);
    const [subSubCategoriesBySubCategory, setSubSubCategoriesBySubCategory] = useState([]);
    const [brands, setBrands] = useState([]);
    const [filters, setFilters] = useState({});

    const handleSubmit = (values) => {
        setPage(1);
        setFilters(values);
    };

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0);
        (async () => {
            const response = await axios.get('/admin/products', {
                withCredentials: true,
                params: {
                    page: page,
                    limit: limit,
                    ...filters
                },
            });
            setProducts(response.data.results);
            setDataCount(response.data.count);
            setLoading(false);
        })();
    }, [page, limit, filters]);

    useEffect(() => {
        (async () => {
            const response = await axios.get('/public/categories');
            setCategories(response.data);
        })();
        (async () => {
            // const response = await axios.get('/public/attr');
            const response = await axios.get('/public/brands');
            setBrands(response.data);
        })();
    }, []);

    // get sub categories based on selected category
    const getSubCategoryByCategory = async (value) => {
        const res = await axios.get(`/public/subcategories?category=${value}`);
        setSubCategoriesByCategory(res.data);
    };

    // get sub sub categories based on selected category
    const getSubSubCategoryByCategory = async (value) => {
        const res = await axios.get(`/public/sub-subcategories?sub_category=${value}`);
        setSubSubCategoriesBySubCategory(res.data);
    };

    const onReset = () => {
        form.resetFields();
        setFilters({});
    };



    return (
        <Layout>
            <div className={`card ${styles.products}`}>
                <h2 className='primary_h2'>Product Management</h2>
                <Link to={'/products/create'}>
                    <Button type='primary'>Create Product</Button>
                </Link>
            </div>

            <div className='card'>
                <Form name='product_filter' onFinish={handleSubmit} form={form}>
                    <Row gutter={[16, 0]}>
                        <Col lg={4}>
                            <Form.Item name='search_term'>
                                <Input placeholder='Search by name' />
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item name='seller_sku'>
                                <Input placeholder='Search by SKU' />
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item name='category'>
                                <Select
                                    placeholder='Search By Category'
                                    onChange={(value) => {
                                        getSubCategoryByCategory(value);
                                    }}
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {sortArrayOfObjectsAlphabetically(categories, 'name').map((item, index) => (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item name='sub_category'>
                                <Select
                                    placeholder='Search By Sub Category'
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    onChange={(value) => {
                                        getSubSubCategoryByCategory(value);
                                    }}
                                >
                                    {sortArrayOfObjectsAlphabetically(subCategoriesByCategory, 'name').map((item, index) => (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item name='sub_subcategories'>
                                <Select
                                    placeholder='Search By Sub Subcategory'
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {sortArrayOfObjectsAlphabetically(subSubCategoriesBySubCategory, 'name').map((item, index) => (
                                        <Select.Option key={index} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item name='brands'>
                                <Select
                                    placeholder='Search By Brands'
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {sortArrayOfObjectsAlphabetically(brands, 'name').map((brand, index) => (
                                        <Select.Option key={index} value={brand.id}>
                                            {brand.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Form.Item name='search_attr'>
                                <Select
                                    placeholder='Search By Filter Attr'
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    <Select.Option value={'recommended'}>Recommended</Select.Option>
                                    <Select.Option value={'bestseller'}>Best Seller</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={4}>
                            <Form.Item name='sort_by'>
                                <Select
                                    placeholder='Sort By'
                                >
                                    <Select.Option value="id">
                                        ID Ascending
                                    </Select.Option>
                                    <Select.Option value="-id">
                                        ID Descending
                                    </Select.Option>
                                    <Select.Option value="brand">
                                        Brand
                                    </Select.Option>
                                    <Select.Option value="category__name">
                                        Category
                                    </Select.Option>
                                    <Select.Option value="sub_category__name">
                                        Sub Category
                                    </Select.Option>
                                    <Select.Option value="seller_sku">
                                        SKU
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={6}>
                            <Form.Item>
                                <Button style={{ marginRight: 5, width: 100 }} type='primary' htmlType='submit'>
                                    Filter
                                </Button>
                                <Button danger={true} style={{ marginLeft: 5, width: 100 }} htmlType='button' onClick={onReset}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className='card'>
                <div className={styles.table_title}>
                    <h2>All Products </h2>
                    {/* <Link
                        to="/"
                        target={'_blank'}
                        className={styles.export}
                    >
                        <SiMicrosoftexcel size={20} />
                        <span>Export To Excel</span>
                    </Link> */}
                </div>

                {loading ? (
                    <Loader height={'50vh'} />
                ) : (
                    <>
                        {products.length > 0 ? (
                            <>
                                <div className='table__wrapper'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Image</th>
                                                <th>SKU</th>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>Brand</th>
                                                <th>Stock</th>
                                                <th>Status</th>
                                                <th>Filter Attr</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.map((product, index) => {
                                                return <ProductItem product={product} key={index} />
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pagination'>
                                    <span>
                                        Showing {(page - 1) * limit + 1} to{' '}
                                        {(page - 1) * limit + limit > dataCount ? dataCount : (page - 1) * limit + limit} of {dataCount} products
                                    </span>
                                    <Pagination
                                        pageSize={limit}
                                        total={dataCount}
                                        current={page}
                                        onChange={(value) => setPage(value)}
                                        onShowSizeChange={(curr, size) => setLimit(size)}
                                        pageSizeOptions={[20, 40, 80, 120, 200]}
                                    />
                                </div>
                            </>
                        ) : (
                            <NoData message='No products found' />
                        )}
                    </>
                )}
            </div>
        </Layout>
    );
};

export default Products;
