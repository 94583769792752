import { Button, Image, message, Select, Switch, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from '../../../config/axios';

const ProductItem = ({product}) => {

    const history = useHistory();
    const [status, setStatus] = useState(product.status);
    const [image, setImage] = useState('');

    useEffect(() => {
        // console.log(product.variants[0].images ? product.variants[0].images[0] : '')
        setImage(product.variants[0]?.images ? product.variants[0].images[0] : '');
    }, []);

    const handleProductSelectAttrChange = value => {
        let payload = {
            filter_attr: value
        }

        axios.patch(`/admin/products/${product.id}`, payload, {withCredentials: true}).then(response => {
            message.success('Product Filter Attr Updated!');
        })
    }

    const duplicateProduct = () => {
        axios.post(`/admin/produts/actions/duplicate/${product.id}`, {}, {withCredentials: true}).then(response => {
            history.push(`/products/edit/${response.data.product_id}`);
            message.success('Product Duplicated Successfully!');
        });
    }

    return (
        <tr>
            <td>{product.id}</td>
            <td>
                {/*<img style={{width: '50px'}} src={image} alt=""/>*/}
                <Image src={image} width={'50px'}/>
            </td>
            <td>{product.seller_sku ? product.seller_sku : 'N/A'}</td>
            <td>{product.title}</td>
            <td>{product.category?.name}</td>
            <td>{product.sub_category?.name}</td>
            <td>{product.brand.name}</td>
            <td>
                {
                    product.variants?.length > 0 ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {
                                product.variants.map((item, index) => {
                                    return <span>{item.value} - <Tag>{item.stock}</Tag></span>
                                })
                            }
                        </div>
                        :
                        <span>N/A</span>
                }
            </td>
            <td>
                <Switch
                    checked={status === 'published'}
                    onChange={value => {
                        console.log(value)
                        setStatus(value ? 'published' : 'draft');
                        axios.patch(`/admin/products/${product.id}`, {status: value ? 'published' : 'draft'}, {withCredentials: true}).then(response => {
                            message.success(`Status changed to ${value ? 'published' : 'draft'}`);
                        });
                    }}
                />
            </td>
            <td>
                <Select
                    defaultValue={product.filter_attr}
                    placeholder={'Select Attribute'}
                        onChange={value => handleProductSelectAttrChange(value)}
                    style={{width: '150px'}}
                >
                    <Select.Option value={'recommended'}>Recommended</Select.Option>
                    <Select.Option value={'bestseller'}>Best Seller</Select.Option>
                </Select>
            </td>
            <td>
                <div className={'d-flex flex-column gap-3'}>
                    <Link to={`/products/edit/${product.id}`} style={{width: '100%'}}>
                        <Button style={{width: '100%'}} type='dashed'>Edit</Button>
                    </Link>
                    <Button type='primary' onClick={duplicateProduct}>Duplicate</Button>
                </div>
            </td>
        </tr>
    );
};

export default ProductItem;