import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";

const Last7DaysSales = ({ salesData, avgSalesData }) => {
    const [totalOrderAmounts, setTotalOrderAmounts] = useState([]);
    const [avgOrderAmount, setAvgOrderAmount] = useState([]);
    const [dateList, setDateList] = useState([]);

    useEffect(() => {
        Object.keys(salesData).forEach((data) => {
            // console.log(salesData[data]);
            setDateList((dateList) => dateList.concat(data));
            setTotalOrderAmounts((noOfOrders) => noOfOrders.concat(parseFloat(salesData[data]).toFixed(2)));
        });

        Object.keys(avgSalesData).forEach((data) => {
            // console.log(salesData[data]);
            setAvgOrderAmount((noOfOrders) => noOfOrders.concat(parseFloat(avgSalesData[data]).toFixed(2)));
        });

        // console.log(response.data);
        // response.data.forEach(data=>{
        //     setTotalOrderAmounts(totalOrderAmounts=>totalOrderAmounts.concat(data.total_bill_amount));
        //     setDateList(dateList=>dateList.concat(data._id));
        //     setTotalDiscountedAmount(totalDiscountedAmount => totalDiscountedAmount.concat(data.total_discount_amount));
        //     setNoOfOrders(avgOrderAmount=>avgOrderAmount.concat(data.total_orders))
        // })
    }, [avgSalesData, salesData]);

    const data = {
        series: [
            {
                name: "Avg Order Value",
                data: avgOrderAmount.reverse(),
            },
            {
                name: "Total Order Value",
                data: totalOrderAmounts.reverse(),
            },
        ],
        options: {
            chart: {
                height: 350,
                type: "area",
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "date",
                categories: dateList.reverse(),
            },
            tooltip: {
                x: {
                    format: "dd/MM/yy",
                },
            },
        },
    };

    return (
        <>
            <ApexCharts options={data.options} series={data.series} width={"100%"} height={450} type="area" />
        </>
    );
};

export default Last7DaysSales;
