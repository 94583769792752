import { WarningFilled } from "@ant-design/icons";
import { Button, Col, Form, Image, Input, Modal, Row, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import Layout from "../../Components/Layout/Layout";
import Loader from "../../Components/Loader/Loader";
import NoData from "../../Components/NoData/NoData";
import axios from "../../config/axios";
import styles from "./Manufacturers.module.scss";

const Manufacturers = () => {
    const [refresh, setRefresh] = useState(null);

    return (
        <Layout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <ManufacturerAddForm setRefresh={setRefresh} />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllManufacturer refresh={refresh} setRefresh={setRefresh} />
                </Col>
            </Row>
        </Layout>
    );
};

export default Manufacturers;

// sub components for this component

// category add form component
const ManufacturerAddForm = ({ setRefresh }) => {
    const [form] = Form.useForm();

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleAddCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];

        // request to server here
        try {
            setLoading(true);
            await axios.post(`/admin/manus`, values, { withCredentials: true });
            message.success("Added New Manufacturer!");
            form.resetFields();
            setImages([]);
            setRefresh(Math.random());
        } catch (error) {
            message.error("A Server Side Error Occurred!");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="card" style={{ minHeight: "80vh" }}>
            <h2 className="primary_h2"> Add New Manufacturer </h2>

            <Form name="addNewBrand" form={form} onFinish={handleAddCategory} layout="vertical" scrollToFirstError requiredMark={false}>
                <Form.Item label="Manufacturer Name" name="name" rules={[{ required: true, message: "Please Add Manufacturer Name" }]}>
                    <Input placeholder="Ruchi" />
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Manufacturer Logo
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{ marginTop: 20 }} loading={loading}>
                        Add New Manufacturer
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

// show all categories component
const ShowAllManufacturer = ({ refresh, setRefresh }) => {
    const [loading, setLoading] = useState(true);
    const [manus, setManus] = useState([]);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        // get All Categories From API
        const getAllCategories = async () => {
            setSpin(true);
            const res = await axios.get("/public/manus", {
                withCredentials: false,
            });

            // sort the categories by name alphabetically
            const sorted = res.data.sort((a, b) => a.name.localeCompare(b.name));
            setManus(sorted);
            setSpin(false);
            setLoading(false);
        };
        getAllCategories();
    }, [refresh]);

    return (
        <div className="card" style={{ minHeight: "80vh" }}>
            <h2 className="primary_h2">All Manufacturers</h2>
            {loading ? (
                <Loader height="100%" />
            ) : (
                <>
                    {manus.length > 0 ? (
                        <Spin spinning={spin} indicator={<Loader height="100%" />}>
                            <div className="table__wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {manus.map((manu, index) => (
                                            <ManuItem key={index} manu={manu} setRefresh={setRefresh} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <span>Total {manus.length} Manufacturers</span>
                            </div>
                        </Spin>
                    ) : (
                        <NoData message="No Manufacturers Found" />
                    )}
                </>
            )}
        </div>
    );
};

// category item component
const ManuItem = ({ manu, setRefresh }) => {
    const [showModal, setShowModal] = useState(false);

    // defining deleteCategory function
    const deleteCategory = async (id) => {
        try {
            await axios.delete(`/admin/manus/${id}`, { withCredentials: true });
            message.success("Deleted the manufacturer");
            setRefresh(Math.random());
        } catch (error) {
            message.error("Something went wrong!");
        }
    };

    // show confirm modal component
    const showConfirm = () => {
        Modal.confirm({
            title: `Are You Sure?`,
            icon: <WarningFilled />,
            content: `Once you delete a Manufacturer, this action can't be undone.`,
            okText: "Yes,Confirm!",

            async onOk() {
                await deleteCategory(manu.id);
            },

            onCancel() {
                message.info("No changes occurred!");
            },
        });
    };

    return (
        <>
            <tr>
                <td>
                    <Image height={50} src={manu.image} alt="logo" />
                </td>
                <td>{manu.name}</td>
                <td>
                    <div className={styles.action_btn}>
                        <FiEdit
                            size={20}
                            title="Edit Category"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        />
                        <MdDelete size={20} title="Delete Category" onClick={showConfirm} />
                    </div>
                </td>
            </tr>
            {showModal && <ManufacturerUpdateModal showModal={showModal} setShowModal={setShowModal} manu={manu} setRefresh={setRefresh} />}
        </>
    );
};

// ManufacturerUpdateModal component
const ManufacturerUpdateModal = ({ showModal, setShowModal, manu, setRefresh }) => {
    // states
    const [images, setImages] = useState(() => [manu?.image]);
    const [updateLoading, setUpdateLoading] = useState(false);

    // defining handleManuUpdate function
    const handleManuUpdate = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];

        try {
            setUpdateLoading(true);
            await axios.put(`/admin/manus/${manu.id}`, values, { withCredentials: true });
            message.success("Manufacturer Updated");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            setUpdateLoading(false);
            setShowModal(false);
            setRefresh(Math.random());
        }
    };

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setShowModal(false);
            }}
            closeIcon={<RiCloseCircleLine size={20} />}
            width={600}
            centered
            footer={null}
        >
            <h2 className="primary_h2"> Update Manufacturer: {manu.name} </h2>
            <Form
                name="updateCategory"
                onFinish={handleManuUpdate}
                layout="vertical"
                initialValues={{
                    ...manu,
                }}
            >
                <Form.Item label="Manufacturer Name" name="name" rules={[{ required: true, message: "Please Add Category Name" }]}>
                    <Input placeholder="Grocery" />
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Manufacturer Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block style={{ marginTop: 20 }} loading={updateLoading}>
                        Update Manufacturer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
