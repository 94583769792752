import React, { useState } from 'react';
import { Button, Input, message, Modal, Select } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import styles from "../ManageProductStyles/Styles.module.scss";
import axios from "../../../config/axios";

const ProductVariant = ({variants, setVariants, variant}) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [variantType, setVariantType] = useState(variant.type);
    const [variantValue, setVariantValue] = useState(variant.value);

    const handleVariantUpdate = () => {
        const updatedVariants = [...variants];
        //find current variant index from variants array
        const index = updatedVariants.findIndex(v => v.id === variant.id);
        //update variant
        updatedVariants[index] = {
            ...variants[index],
            type: variantType,
            value: variantValue
        }

        setVariants(updatedVariants);

        setShowEditModal(false);
    }

    const handleVariantDelete = () => {
        Modal.confirm({
            title: 'Delete Variant',
            content: 'Are you sure you want to delete this variant? This operation cannot be undone.',
            onOk() {
                axios.delete(`/admin/products/variants/mod/${variant.id}`, {withCredentials: true}).then(response => {
                    message.success('Variant Deleted Successfully!');
                    window.location.reload();
                })
            }

        })
    }

    return (
        <tr>
            <td style={{textTransform: 'uppercase'}}>{variant.type}</td>
            <td style={{textTransform: 'uppercase'}}>{variant.value}</td>
            <td style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Button type={'primary'} onClick={() => setShowEditModal(true)}>
                    <MdOutlineEdit size={20}/>
                </Button>
                <Button danger={true} onClick={handleVariantDelete}>
                    <FaRegTrashAlt size={16}/>
                </Button>
            </td>
            <Modal open={showEditModal} title={'Edit Variant'} footer={null} onCancel={() => setShowEditModal(false)}
                   destroyOnClose={true}>
                <div>
                    <div className={styles.formItem}>
                        <label htmlFor="">Variant Type: </label>
                        <Select
                            value={variantType}
                            onChange={value => setVariantType(value)}
                            style={{width: '100%'}}

                        >
                            <Select.Option value={'weight_g'}>Weight - G</Select.Option>
                            <Select.Option value={'weight_kg'}>Weight - KG</Select.Option>
                            <Select.Option value={'volume_ml'}>Volume - ML</Select.Option>
                            <Select.Option value={'volume_l'}>Volume - L</Select.Option>
                        </Select>
                    </div>
                    <br/>
                    <div className={styles.formItem}>
                        <label htmlFor="">Variant Value: </label>
                        <Input
                            onChange={e => setVariantValue(e.target.value)}
                            value={variantValue}
                        />
                    </div>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type={'primary'} onClick={handleVariantUpdate}>Update Variant</Button>
                    </div>
                </div>
            </Modal>
        </tr>
    );
};

export default ProductVariant;