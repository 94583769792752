import styles from './Loader.module.scss';
import logo from "./images/logo.svg";

const Loader = ({ height }) => {
    return (
        <div className={styles.loader} style={{ height: height ? height : '10vh' }}>
            <img src={logo} alt="logo" className={styles.icon} />
        </div>
    );
}

export default Loader;