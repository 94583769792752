import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Modal, message, Spin, Image, Select } from 'antd';

// importing styles
import styles from './SubCategories.module.scss';

// importing components
import ImageUploader from '../../Components/ImageUploader/ImageUploader';
import Layout from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import NoData from '../../Components/NoData/NoData';

// importing axios
import axios from '../../config/axios';

// importing icons
import { FiEdit } from "react-icons/fi";
import { WarningFilled } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";


const SubCategories = () => {
    const [refresh, setRefresh] = useState(null);
    return (
        <Layout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <SubCategoryAddForm setRefresh={setRefresh} />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllSubCategories refresh={refresh} setRefresh={setRefresh} />
                </Col>
            </Row>
        </Layout>
    );
}

export default SubCategories;


// sub components for this component

// sub category add form component
const SubCategoryAddForm = ({ setRefresh }) => {

    const [form] = Form.useForm();

    const [images, setImages] = useState([]);
    const [addSubCategoryLoading, setAddSubCategoryLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    const [banner, setBanner] = useState([]);
    const [mobileBanner, setMobileBanner] = useState([]);

    useEffect(() => {
        // get All Categories From API for select
        const getAllCategories = async () => {
            const res = await axios.get("/public/categories", {
                withCredentials: false,
            });
            setCategories(res.data);
        };
        getAllCategories();
    }, []);

    const handleAddSubCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];
        values.banner = banner.length > 0 ? banner[0] : null;
        values.mobile_banner = mobileBanner.length > 0 ? mobileBanner[0] : null;

        // request to server here
        try {
            setAddSubCategoryLoading(true)
            await axios.post(`/admin/subcategories`, values, { withCredentials: true })
            message.success("Added New Sub Category!");
            form.resetFields();
            setImages([]);
            setRefresh(Math.random());
        } catch (error) {
            message.error(`Can't create sub category right now!`)
        } finally {
            setAddSubCategoryLoading(false);
        }
    }

    return (
        <div className="card" style={{ minHeight: '80vh' }}>
            <h2 className="primary_h2"> Add New Sub Category </h2>

            <Form
                name="addNewSubCategory"
                form={form}
                onFinish={handleAddSubCategory}
                layout="vertical"
                scrollToFirstError
                requiredMark={false}
            >
                <Form.Item
                    label="Sub Category Name"
                    name="name"
                    rules={[{ required: true, message: "Please Add Sub Category Name" }]}
                >
                    <Input placeholder="Fruits" />
                </Form.Item>

                <Form.Item
                    label="Select Category"
                    name="category"
                    rules={[{ required: true, message: "Please Select Category" }]}
                >
                    <Select placeholder="Select a Category">
                        {categories.map((option, index) => (
                            <Select.Option value={option.id} key={index}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Sub Category Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>
<br/>
                 <div className={styles.upload_image_container}>
                    <label htmlFor="banner">
                        Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setBanner} multiple={false}/>
                    {banner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <br/>
                <div className={styles.upload_image_container}>
                    <label htmlFor="mbl_banner">
                        Mobile Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setMobileBanner} multiple={false}/>
                    {mobileBanner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: 20 }}
                        loading={addSubCategoryLoading}
                    >
                        Add New Sub Category
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

// show all categories component
const ShowAllSubCategories = ({ refresh, setRefresh }) => {

    const [subcategoriesLoading, setSubcategoriesLoading] = useState(true);
    const [subcategories, setSubcategories] = useState([]);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        // get All Sub Categories From API
        const getAllSubCategories = async () => {
            setSpin(true);
            const res = await axios.get("/public/subcategories", {
                withCredentials: false,
            });
            setSubcategories(res.data);
            setSpin(false);
            setSubcategoriesLoading(false);
        };
        getAllSubCategories();
    }, [refresh]);

    return (
        <div className="card" style={{ minHeight: '80vh' }}>
            <h2 className="primary_h2">All Sub Categories</h2>
            {subcategoriesLoading ? (
                <Loader height="100%" />
            ) :
                (
                    <>
                        {subcategories.length > 0 ? (
                            <Spin spinning={spin} indicator={<Loader height="100%" />}>
                                <div className='table__wrapper'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subcategories.map((subcategory, index) => (
                                                <SubCategoryItem
                                                    key={index}
                                                    subcategory={subcategory}
                                                    setRefresh={setRefresh}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: 20 }}>
                                    <span>Total {subcategories.length} Sub Categories</span>
                                </div>
                            </Spin>
                        ) : (
                            <NoData message="No Sub Categories Found" />
                        )}
                    </>
                )
            }
        </div>
    )
}

// category item component
const SubCategoryItem = ({ subcategory, setRefresh }) => {
    const [showModal, setShowModal] = useState(false);

    // defining deleteCategory function
    const deleteSubCategory = async (id) => {
        try {
            await axios.delete(`/admin/subcategories/${id}`, { withCredentials: true });
            message.success("Deleted the Sub category");
            setRefresh(Math.random());
        } catch (error) {
            message.error("Something went wrong!")
        }
    }

    // show confirm modal component
    const showConfirm = () => {
        Modal.confirm({
            title: `Are You Sure?`,
            icon: <WarningFilled />,
            content: `Once you delete a sub category, this action can't be undone.`,
            okText: 'Yes,Confirm!',

            async onOk() {
                await deleteSubCategory(subcategory.id);
            },

            onCancel() {
                message.info("No changes occurred!")
            },
        });
    };

    return (
        <>
            <tr>
                <td>
                    <Image height={50} src={subcategory.image} alt="logo" />
                </td>
                <td>{subcategory.name}</td>
                <td>
                    <div className={styles.action_btn}>
                        <FiEdit
                            size={20}
                            title="Edit Sub Category"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        />
                        <MdDelete
                            size={20}
                            title="Delete Sub Category"
                            onClick={showConfirm}
                        />
                    </div>
                </td>
            </tr>
            {showModal && (
                <SubCategoryUpdateModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    subcategory={subcategory}
                    setRefresh={setRefresh}
                />
            )}
        </>

    )
}

// CategoryUpdateModal component
const SubCategoryUpdateModal = ({ showModal, setShowModal, subcategory, setRefresh }) => {

    // states
    const [images, setImages] = useState(() => [subcategory.image]);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [banner, setBanner] = useState(subcategory.banner ? [subcategory.banner] : []);
    const [mobileBanner, setMobileBanner] = useState(subcategory.mobile_banner ? [subcategory.mobile_banner] : []);

    useEffect(() => {
        // get All Categories From API for select
        const getAllCategories = async () => {
            const res = await axios.get("/public/categories", {
                withCredentials: false,
            });
            setCategories(res.data);
        };
        getAllCategories();
    }, []);

    // defining handleUpdateCategory function
    const handleUpdateSubCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];
        values.banner = banner.length > 0 ? banner[0] : null;
        values.mobile_banner = mobileBanner.length > 0 ? mobileBanner[0] : null;

        try {
            setUpdateLoading(true);
            await axios.put(`/admin/subcategories/${subcategory.id}`, values, { withCredentials: true });
            message.success("Updated the Sub category");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            setUpdateLoading(false);
            setShowModal(false);
            setRefresh(Math.random());
        }
    }

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setShowModal(false);
            }}
            closeIcon={<RiCloseCircleLine size={20} />}
            width={600}
            centered
            footer={null}
        >
            <h2 className="primary_h2"> Update Sub Category: {subcategory.name} </h2>
            <Form
                name="updateSubCategory"
                onFinish={handleUpdateSubCategory}
                layout="vertical"
                initialValues={{
                    ...subcategory,
                }}
            >
                <Form.Item
                    label="Sub Category Name"
                    name="name"
                    rules={[{ required: true, message: "Please Add Sub Category Name" }]}
                >
                    <Input placeholder="Grocery" />
                </Form.Item>

                <Form.Item
                    label="Select Category"
                    name="category"
                    rules={[{ required: true, message: "Please Select Category" }]}
                >
                    <Select placeholder="Select a Category">
                        {categories.map((option, index) => (
                            <Select.Option value={option.id} key={index}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Sub Category Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>


                <div className={styles.upload_image_container}>
                    <label htmlFor="banner">
                        Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setBanner} multiple={false}/>
                    {banner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <div className={styles.upload_image_container}>
                    <label htmlFor="mbl_banner">
                        Mobile Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setMobileBanner} multiple={false}/>
                    {mobileBanner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: 20 }}
                        loading={updateLoading}
                    >
                        Update Sub Category
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}