import styles from './NoData.module.scss';

const NoData = ({ height, message }) => {
    return (
        <div style={{ minHeight: height ?? "30vh" }} className={styles.noData}>
            <h2>{message ?? "No data found"}</h2>
        </div>
    );
};

export default NoData;
