import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Modal, message, Spin, Image, Select } from 'antd';

// importing styles
import styles from './Brands.module.scss';

// importing components
import ImageUploader from '../../Components/ImageUploader/ImageUploader';
import Layout from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import NoData from '../../Components/NoData/NoData';

// importing axios
import axios from '../../config/axios';

// importing icons
import { FiEdit } from "react-icons/fi";
import { WarningFilled } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

const Brands = () => {

    const [refresh, setRefresh] = useState(null);

    return (
        <Layout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <BrandAddForm setRefresh={setRefresh}/>
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllBrands refresh={refresh} setRefresh={setRefresh}/>
                </Col>
            </Row>
        </Layout>
    );
}

export default Brands;


// sub components for this component

// category add form component
const BrandAddForm = ({setRefresh}) => {

    const [form] = Form.useForm();

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [manus, setManus] = useState([]);

    const [banner, setBanner] = useState([]);
    const [mobileBanner, setMobileBanner] = useState([]);

    useEffect(() => {
        axios.get('/public/manus').then(response => {
            setManus(response.data);
        })
    }, []);

    const handleAddCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];
        values.banner = banner.length > 0 ? banner[0] : null;
        values.mobile_banner = mobileBanner.length > 0 ? mobileBanner[0] : null;

        // request to server here
        try {
            setLoading(true)
            await axios.post(`/admin/brands`, values, {withCredentials: true})
            message.success("Added New Brand!");
            form.resetFields();
            setImages([]);
            setRefresh(Math.random());
        } catch (error) {
            message.error('A Server Side Error Occurred!');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="card" style={{minHeight: '80vh'}}>
            <h2 className="primary_h2"> Add New Brand </h2>

            <Form
                name="addNewBrand"
                form={form}
                onFinish={handleAddCategory}
                layout="vertical"
                scrollToFirstError
                requiredMark={false}
            >
                <Form.Item
                    label="Brand Name"
                    name="name"
                    rules={[{required: true, message: "Please Add Brand Name"}]}
                >
                    <Input placeholder="Ruchi"/>
                </Form.Item>

                <Form.Item
                    label="Manufacturer"
                    name="manufacturer"
                    rules={[{required: false, message: "Please Add Brand Name"}]}
                >
                    <Select
                        placeholder={'Select Manufacturer'}
                    >
                        {
                            manus.map((manu, index) => {
                                return <Select.Option key={index} value={manu.id}>{manu.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Brand Logo
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false}/>
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <div className={styles.upload_image_container}>
                    <label htmlFor="banner">
                        Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setBanner} multiple={false}/>
                    {banner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <div className={styles.upload_image_container}>
                    <label htmlFor="mbl_banner">
                        Mobile Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setMobileBanner} multiple={false}/>
                    {mobileBanner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{marginTop: 20}}
                        loading={loading}
                    >
                        Add New Brand
                    </Button>
                </Form.Item>
            </Form>


        </div>
    )
}

// show all categories component
const ShowAllBrands = ({refresh, setRefresh}) => {

    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        // get All Categories From API
        const getAllCategories = async () => {
            setSpin(true);
            const res = await axios.get("/public/brands", {
                withCredentials: false,
            });
            setBrands(res.data);
            setSpin(false);
            setLoading(false);
        };
        getAllCategories();
    }, [refresh]);

    return (
        <div className="card" style={{minHeight: '80vh'}}>
            <h2 className="primary_h2">All Brands</h2>
            {loading ? (
                    <Loader height="100%"/>
                ) :
                (
                    <>
                        {brands.length > 0 ? (
                            <Spin spinning={spin} indicator={<Loader height="100%"/>}>
                                <div className='table__wrapper'>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Manufacturer</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {brands.map((brand, index) => (
                                            <BrandItem
                                                key={index}
                                                brand={brand}
                                                setRefresh={setRefresh}
                                            />
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{marginTop: 20}}>
                                    <span>Total {brands.length} Brands</span>
                                </div>
                            </Spin>
                        ) : (
                            <NoData message="No Brands Found"/>
                        )}
                    </>
                )
            }
        </div>
    )
}

// category item component
const BrandItem = ({brand, setRefresh}) => {
    const [showModal, setShowModal] = useState(false);

    // defining deleteCategory function
    const deleteCategory = async (id) => {
        try {
            await axios.delete(`/admin/brands/${id}`, {withCredentials: true});
            message.success("Deleted the brand");
            setRefresh(Math.random());
        } catch (error) {
            message.error("Something went wrong!")
        }
    }

    // show confirm modal component
    const showConfirm = () => {
        Modal.confirm({
            title: `Are You Sure?`,
            icon: <WarningFilled/>,
            content: `Once you delete a Brand, this action can't be undone.`,
            okText: 'Yes,Confirm!',

            async onOk() {
                await deleteCategory(brand.id);
            },

            onCancel() {
                message.info("No changes occurred!")
            },
        });
    };

    return (
        <>
            <tr>
                <td>
                    <Image height={50} src={brand.image} alt="logo"/>
                </td>
                <td>{brand.name}</td>
                <td>{brand.manufacturer ? brand.manufacturer.name : 'N/A'}</td>
                <td>
                    <div className={styles.action_btn}>
                        <FiEdit
                            size={20}
                            title="Edit Category"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        />
                        <MdDelete
                            size={20}
                            title="Delete Category"
                            onClick={showConfirm}
                        />
                    </div>
                </td>
            </tr>
            {showModal && (
                <CategoryUpdateModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    brand={brand}
                    setRefresh={setRefresh}
                />
            )}
        </>

    )
}

// CategoryUpdateModal component
const CategoryUpdateModal = ({showModal, setShowModal, brand, setRefresh}) => {

    // states
    const [images, setImages] = useState(() => [brand.image]);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [manus, setManus] = useState([]);

    const [banner, setBanner] = useState(brand.banner ? [brand.banner] : []);
    const [mobileBanner, setMobileBanner] = useState(brand.mobile_banner ? [brand.mobile_banner] : []);

    useEffect(() => {
        axios.get('/public/manus').then(response => {
            setManus(response.data);
        })
    }, []);

    // defining handleBrandUpdate function
    const handleBrandUpdate = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];
        values.banner = banner.length > 0 ? banner[0] : null;
        values.mobile_banner = mobileBanner.length > 0 ? mobileBanner[0] : null;

        try {
            setUpdateLoading(true);
            await axios.put(`/admin/brands/${brand.id}`, values, {withCredentials: true});
            message.success("Brand Updated");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            setUpdateLoading(false);
            setShowModal(false);
            setRefresh(Math.random());
        }
    }

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setShowModal(false);
            }}
            closeIcon={<RiCloseCircleLine size={20}/>}
            width={600}
            centered
            footer={null}
        >
            <h2 className="primary_h2"> Update Brand: {brand.name} </h2>
            <Form
                name="updateCategory"
                onFinish={handleBrandUpdate}
                layout="vertical"
                initialValues={{
                    ...brand,
                    manufacturer: brand.manufacturer ? brand.manufacturer.id : null
                }}
            >
                <Form.Item
                    label="Category Name"
                    name="name"
                    rules={[{required: true, message: "Please Add Category Name"}]}
                >
                    <Input placeholder="Grocery"/>
                </Form.Item>

                <Form.Item
                    label="Manufacturer"
                    name="manufacturer"
                    rules={[{required: false, message: "Please Add Brand Name"}]}
                >
                    <Select
                        placeholder={'Select Manufacturer'}
                    >
                        {
                            manus.map((manu, index) => {
                                return <Select.Option key={index} value={manu.id}>{manu.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Brand Logo
                    </label>
                    <ImageUploader setter={setImages} multiple={false}/>
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>


                <div className={styles.upload_image_container}>
                    <label htmlFor="banner">
                        Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setBanner} multiple={false}/>
                    {banner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <div className={styles.upload_image_container}>
                    <label htmlFor="mbl_banner">
                        Mobile Banner
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setMobileBanner} multiple={false}/>
                    {mobileBanner.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images"/>
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{marginTop: 20}}
                        loading={updateLoading}
                    >
                        Update Brand
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}