import React, { createContext, useState, useEffect } from 'react';

//importing components
import Loader from '../Components/Loader/Loader';
import axios from '../config/axios';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        (async () => {
            try {
                let response = await axios.get(`/users/authenticate`, { withCredentials: true });
                if (response.data.role === 'admin') {
                    setIsAuthenticated(true);
                    setUser(response.data);
                } else {
                    setUser(null);
                }
            } catch (error) {
                setUser(null);
            } finally {
                setIsLoaded(true);
            }
        })();
    }, []);


    return (
        <div>
            {!isLoaded ?
                <Loader height={'100vh'} />
                :
                <AuthContext.Provider
                    value={{ user, setUser, isAuthenticated, setIsAuthenticated }}
                >
                    {children}
                </AuthContext.Provider>
            }
        </div>
    )
}

export default AuthProvider;
