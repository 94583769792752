import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Modal, message, Spin, Image } from 'antd';

// importing styles
import styles from './Categories.module.scss';

// importing components
import ImageUploader from '../../Components/ImageUploader/ImageUploader';
import Layout from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import NoData from '../../Components/NoData/NoData';

// importing axios
import axios from '../../config/axios';

// importing icons
import { FiEdit } from "react-icons/fi";
import { WarningFilled } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

const Categories = () => {

    const [refresh, setRefresh] = useState(null);

    return (
        <Layout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <CategoryAddForm setRefresh={setRefresh} />
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <ShowAllCategories refresh={refresh} setRefresh={setRefresh} />
                </Col>
            </Row>
        </Layout>
    );
}

export default Categories;



// sub components for this component

// category add form component
const CategoryAddForm = ({ setRefresh }) => {

    const [form] = Form.useForm();

    const [images, setImages] = useState([]);
    const [addCategoryLoading, setAddCategoryLoading] = useState(false);

    const handleAddCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];

        // request to server here
        try {
            setAddCategoryLoading(true)
            await axios.post(`/admin/categories`, values, { withCredentials: true })
            message.success("Added New Category!");
            form.resetFields();
            setImages([]);
            setRefresh(Math.random());
        } catch (error) {
            message.error(`Can't create category right now!`)
        } finally {
            setAddCategoryLoading(false);
        }
    }

    return (
        <div className="card" style={{ minHeight: '80vh' }}>
            <h2 className="primary_h2"> Add New Category </h2>

            <Form
                name="addNewCategory"
                form={form}
                onFinish={handleAddCategory}
                layout="vertical"
                scrollToFirstError
                requiredMark={false}
            >
                <Form.Item
                    label="Category Name"
                    name="name"
                    rules={[{ required: true, message: "Please Add Category Name" }]}
                >
                    <Input placeholder="Grocery" />
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Category Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: 20 }}
                        loading={addCategoryLoading}
                    >
                        Add New Category
                    </Button>
                </Form.Item>
            </Form>



        </div>
    )
}

// show all categories component
const ShowAllCategories = ({ refresh, setRefresh }) => {

    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        // get All Categories From API
        const getAllCategories = async () => {
            setSpin(true);
            const res = await axios.get("/public/categories", {
                withCredentials: false,
            });
            setCategories(res.data);
            setSpin(false);
            setCategoriesLoading(false);
        };
        getAllCategories();
    }, [refresh]);

    return (
        <div className="card" style={{ minHeight: '80vh' }}>
            <h2 className="primary_h2">All Categories</h2>
            {categoriesLoading ? (
                <Loader height="100%" />
            ) :
                (
                    <>
                        {categories.length > 0 ? (
                            <Spin spinning={spin} indicator={<Loader height="100%" />}>
                                <div className='table__wrapper'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categories.map((category, index) => (
                                                <CategoryItem
                                                    key={index}
                                                    category={category}
                                                    setRefresh={setRefresh}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: 20 }}>
                                    <span>Total {categories.length} Categories</span>
                                </div>
                            </Spin>
                        ) : (
                            <NoData message="No Categories Found" />
                        )}
                    </>
                )
            }
        </div>
    )
}

// category item component
const CategoryItem = ({ category, setRefresh }) => {
    const [showModal, setShowModal] = useState(false);

    // defining deleteCategory function
    const deleteCategory = async (id) => {
        try {
            await axios.delete(`/admin/categories/${id}`, { withCredentials: true });
            message.success("Deleted the category");
            setRefresh(Math.random());
        } catch (error) {
            message.error("Something went wrong!")
        }
    }

    // show confirm modal component
    const showConfirm = () => {
        Modal.confirm({
            title: `Are You Sure?`,
            icon: <WarningFilled />,
            content: `Once you delete a category, this action can't be undone.`,
            okText: 'Yes,Confirm!',

            async onOk() {
                await deleteCategory(category.id);
            },

            onCancel() {
                message.info("No changes occurred!")
            },
        });
    };

    return (
        <>
            <tr>
                <td>
                    <Image height={50} src={category.image} alt="logo" />
                </td>
                <td>{category.name}</td>
                <td>
                    <div className={styles.action_btn}>
                        <FiEdit
                            size={20}
                            title="Edit Category"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        />
                        <MdDelete
                            size={20}
                            title="Delete Category"
                            onClick={showConfirm}
                        />
                    </div>
                </td>
            </tr>
            {showModal && (
                <CategoryUpdateModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    category={category}
                    setRefresh={setRefresh}
                />
            )}
        </>

    )
}

// CategoryUpdateModal component
const CategoryUpdateModal = ({ showModal, setShowModal, category, setRefresh }) => {

    // states
    const [images, setImages] = useState(() => [category.image]);
    const [updateLoading, setUpdateLoading] = useState(false);

    // defining handleUpdateCategory function
    const handleUpdateCategory = async (values) => {
        if (images.length < 1) {
            message.error("Upload at least one image!");
            return;
        }
        if (images.length > 1) {
            message.error("Upload only one image!");
            return;
        }
        values.image = images[0];

        try {
            setUpdateLoading(true);
            await axios.put(`/admin/categories/${category.id}`, values, { withCredentials: true });
            message.success("Updated the category");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            setUpdateLoading(false);
            setShowModal(false);
            setRefresh(Math.random());
        }
    }

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setShowModal(false);
            }}
            closeIcon={<RiCloseCircleLine size={20} />}
            width={600}
            centered
            footer={null}
        >
            <h2 className="primary_h2"> Update Category: {category.name} </h2>
            <Form
                name="updateCategory"
                onFinish={handleUpdateCategory}
                layout="vertical"
                initialValues={{
                    ...category,
                }}
            >
                <Form.Item
                    label="Category Name"
                    name="name"
                    rules={[{ required: true, message: "Please Add Category Name" }]}
                >
                    <Input placeholder="Grocery" />
                </Form.Item>

                <div className={styles.upload_image_container}>
                    <label htmlFor="images">
                        Category Image
                        {/* (1200 x 450 px) */}
                    </label>
                    <ImageUploader setter={setImages} multiple={false} />
                    {images.map((image, index) => (
                        <div className={styles.image_group_container} key={index}>
                            <img src={image} alt="images" />
                        </div>
                    ))}
                </div>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{ marginTop: 20 }}
                        loading={updateLoading}
                    >
                        Update Category
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}