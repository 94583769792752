import { Button, Form, Input, message } from "antd";
import { useContext, useState } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { AuthContext } from "../../Contexts/AuthContext";
import axios from "../../config/axios";
import styles from "./Login.module.scss";
import logo from "./images/logo.svg";

const Login = (props) => {
    const { setUser, setIsAuthenticated } = useContext(AuthContext);

    // states
    const [loading, setLoading] = useState(false);

    const Login = async (values) => {
        try {
            setLoading(true);

            const response = await axios.post("/users/login", values);

            if (response.data.user.role === "admin") {
                setUser(response.data.user);
                setIsAuthenticated(true);
                localStorage.setItem("device_id", response.data.access_token);
                props.history.push("/");
            } else {
                message.error("Unauthorized!");
            }
        } catch (error) {
            message.error("Invalid credentials!");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.logo}>
                    <img src={logo} alt="logo" />
                </div>

                <span className={styles.title}>Desi Fresh Business Dashboard</span>
                <span className={styles.subtitle}>Welcome Back! Sign in to continue.</span>

                <Form name="login" onFinish={Login} layout="vertical" requiredMark={false}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" block loading={loading} htmlType="submit">
                            Login <FaSignInAlt size={20} />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
