import { Row, Col, Form, Select, Input, DatePicker, message, Button, Checkbox, Divider } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import axios from '../../../config/axios';

// import styles
import styles from '../ManageProductStyles/Styles.module.scss';

// data
const currencyOptions = [
    {
        name: 'Euro €',
        value: 'euro',
    },
];

const taxCategoryOptions = [
    {
        name: 'VAT (Deutschland)',
        value: 'tax_category_1',
    },
];

const taxRateOptions = [
    {
        name: '7.00%',
        value: '7.00',
    },
    {
        name: '19.00%',
        value: '19.00',
    },
];

const canBuyMaxOptions = [
    {
        name: '1',
        value: 1,
    },
    {
        name: '2',
        value: 2,
    },
    {
        name: '3',
        value: 3,
    },
    {
        name: '5',
        value: 5,
    },
    {
        name: '10',
        value: 10,
    },
    {
        name: '25',
        value: 25,
    },
    {
        name: '50',
        value: 50,
    },
    {
        name: '100',
        value: 100,
    },
    {
        name: '200',
        value: 200,
    },
    {
        name: '500',
        value: 500,
    }
]

const ProductPrice = ({ product }) => {
    const [form] = Form.useForm();
    const [, setRefreshX] = useState(null);

    const [updatePriceLoading, setUpdatePriceLoading] = useState(false);


    // checkbox options
    const FulfillmentOptions = [
        {
            label: 'Shipped only by Desi Fresh',
            value: 'only_desi_fresh',
        },
        {
            label: 'Shipped  by Desi Fresh and other fulfillment Services ',
            value: 'desi_fresh_other',
        },
        {
            label: 'Shipped  only by other fulfillment Services ',
            value: 'only_other',
        },
    ];

    // on submit form handler function
    const updateProductPrices = async (values) => {
        const variants = product.variants
        console.log(variants);

        const payload = {
            ...values,
            // buying_price: Number(values['buying_price']).toFixed(2),
            // markup: Number(values['markup']).toFixed(2),
            sale_from_date: values['sale_from_date'] ? values['sale_from_date'].format() : null,
            sale_end_date: values['sale_end_date'] ? values['sale_end_date'].format() : null,
            re_stock_date: values['re_stock_date'] ? values['re_stock_date'].format() : null,
        }

        console.log(payload);



        try {
            setUpdatePriceLoading(true)
            // first patch the variant endpoint with updated data
            await axios.patch(`/admin/products/variants/${product.id}`, { variants: variants }, { withCredentials: true })

            // then hit the patch product endpoint with payloads
            await axios.patch(`/admin/products/${product.id}`, payload, { withCredentials: true })
            message.success("Updated Product data");
        } catch (error) {
            message.error('Something went wrong! Try again.')
        } finally {
            setUpdatePriceLoading(false)
        }
    };


    return (
        <Form
            form={form}
            onFinish={updateProductPrices}
            layout='horizontal'
            initialValues={
                product && {
                    ...product,
                    sale_from_date: product.sale_from_date ? moment(product.sale_from_date) : null,
                    sale_end_date: product.sale_end_date ? moment(product.sale_end_date) : null,
                    re_stock_date: product.re_stock_date ? moment(product.re_stock_date) : null,

                    currency: 'euro',
                    tax_category: 'tax_category_1',
                }
            }
            scrollToFirstError
        >
            <Row gutter={[20, 16]}>
                <Col lg={8}>
                    <Form.Item
                        name='currency'
                        label='Currency'
                        rules={[{ required: true, message: 'Select a currency' }]}
                        required
                    >
                        <Select placeholder='Select a currency'>
                            {currencyOptions.map((currency, index) => (
                                <Select.Option key={index} value={currency.value}>
                                    {currency.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={10}>
                    <Form.Item
                        name='tax_category'
                        label='Tax category'
                        rules={[{ required: true, message: 'Select a tax category' }]}
                        required
                    >
                        <Select placeholder='Select a tax category'>
                            {taxCategoryOptions.map((category, index) => (
                                <Select.Option key={index} value={category.value}>
                                    {category.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={6}>
                    <Form.Item
                        name='tax_rate'
                        rules={[{ required: true, message: 'Select tax rate' }]}
                        required
                    >
                        <Select placeholder='Select tax rate'>
                            {taxRateOptions.map((option, index) => (
                                <Select.Option key={index} value={option.value}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={24}>
                    <Form.Item
                        label='Seller SKU'
                        name='seller_sku'
                        rules={[{ required: true, message: 'Enter seller sku' }]}
                        required
                    >
                        <Input placeholder='SKU-5863' />
                    </Form.Item>
                </Col>
                <Col lg={24}>
                    <Divider style={{ marginTop: 0 }}>Variant Management</Divider>
                    {product.variants.map((variant, index) => (
                        <Row gutter={[16, 16]} key={index}>
                            <Col span={4}>
                                <Form.Item
                                    label='Variant Type'
                                >
                                    <Input
                                        readOnly
                                        value={variant.type}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label='Variant Value'
                                >
                                    <Input
                                        readOnly
                                        value={variant.value}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    label='Buying Price'
                                >
                                    <Input
                                        type="number"
                                        value={variant.buying_price}
                                        onChange={(e) => {
                                            variant.buying_price = e.target.value;
                                            setRefreshX(Math.random());
                                        }}
                                        min={0}
                                        step=".01"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    label='Markup'
                                >
                                    <Input
                                        type="number"
                                        value={variant.markup}
                                        onChange={(e) => {
                                            variant.markup = e.target.value;
                                            setRefreshX(Math.random());
                                        }}
                                        min={0}
                                        step=".01"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    label='Discount (%)'
                                >
                                    <Input
                                        type="number"
                                        value={variant.discount_percentage}
                                        onChange={(e) => {
                                            variant.discount_percentage = e.target.value;
                                            setRefreshX(Math.random());
                                        }}
                                        min={0}
                                        max={99}
                                        step=".01"
                                        disabled={!variant.price}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    label='Price'
                                >
                                    <Input
                                        type="number"
                                        value={variant.price}
                                        onChange={(e) => {
                                            variant.price = e.target.value;
                                            setRefreshX(Math.random());
                                        }}
                                        min={0}
                                        step=".01"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    label='Stock'
                                >
                                    <Input
                                        type="number"
                                        value={variant.stock}
                                        onChange={(e) => {
                                            variant.stock = e.target.value;
                                            setRefreshX(Math.random());
                                        }}
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    ))}


                    <Divider style={{ marginTop: 0 }} />
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='sale_from_date'
                        label='Sale from date'
                        rules={[
                            {
                                type: 'object',
                                required: false,
                                message: 'Please select sale start date!',
                            }
                        ]}
                    >
                        <DatePicker placeholder='DD/MM/YYYY' format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='sale_end_date'
                        label='Sale end date'
                    >
                        <DatePicker placeholder='DD/MM/YYYY' format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                {/* <Col lg={12}>
                    <Form.Item
                        label='Quantity in Stock'
                        name='stock'
                        rules={[{ required: true, message: 'Enter quantity in stock' }]}
                        required
                    >
                        <Input type="number" placeholder="Type only numbers here e.g. 100" />
                    </Form.Item>
                </Col> */}



                <Col span={12}>
                    <Form.Item name='re_stock_date' label='Re-stock date'>
                        <DatePicker placeholder='DD/MM/YYYY' format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col lg={12}>
                    <Form.Item
                        label='Low on Stock'
                        name='low_stock'
                        rules={[{ required: false, message: 'Enter low on stock amount' }]}
                    >
                        {/* <Input type="number" placeholder="Type only numbers here e.g. 20" min={0} /> */}
                        <Select>
                            <Select.Option value={5}>5</Select.Option>
                            <Select.Option value={10}>10</Select.Option>
                            <Select.Option value={25}>25</Select.Option>
                            <Select.Option value={50}>50</Select.Option>
                            <Select.Option value={100}>100</Select.Option>
                            <Select.Option value={200}>200</Select.Option>
                            <Select.Option value={500}>500</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name='max_quantity'
                        label='Customers can buy a max quantity of?'
                        rules={[{ required: false, message: 'Select a option' }]}
                    >
                        <Select placeholder='Select a option'>
                            {canBuyMaxOptions.map((option, index) => (
                                <Select.Option key={index} value={option.value}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='gift_packaging'
                        label='Gift packaging available'
                        rules={[{ required: false, message: 'Select a option' }]}
                    >
                        <Select placeholder='Select a option'>
                            <Select.Option value={true}>
                                Yes
                            </Select.Option>
                            <Select.Option value={false}>
                                No
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='gift_packaging_msg'
                        label='Gift packaging Message'
                        rules={[{ required: false, message: 'Select a option' }]}>
                        <Select placeholder='Select a option'>
                            <Select.Option value={true}>
                                Yes
                            </Select.Option>
                            <Select.Option value={false}>
                                No
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='condition'
                        label='Conditions'
                        rules={[{ required: false, message: 'Select a option' }]}
                    >
                        <Select placeholder='Select a option'>
                            <Select.Option value="non_refundable">
                                Non refundable
                            </Select.Option>
                            <Select.Option value="refundable">
                                Refundable
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label='Condition Notes'
                        name='condition_notes'
                        rules={[{ required: false, message: 'Enter condition notes' }]}
                    >
                        <Input.TextArea autoSize />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <div className={styles.fulfillment_channel}>
                        <label
                            htmlFor="fulfillment_channel"
                            className={styles.channel_label}>
                            Fulfillment  Channel:
                        </label>
                        <Checkbox.Group
                            options={FulfillmentOptions}
                            defaultValue={['only_desi_fresh']}
                            onChange={(checkedValues) => {
                                console.log('checked = ', checkedValues);
                            }}
                            style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                        />
                    </div>
                </Col>
                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item>
                        <Button
                            style={{ width: '100%', maxWidth: '150px', minWidth: '150px' }}
                            type='primary'
                            htmlType='submit'
                            loading={updatePriceLoading}
                            disabled={updatePriceLoading}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ProductPrice;
