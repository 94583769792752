import { Button, Card, Col, DatePicker, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { BiStats } from "react-icons/bi";
import { CiDiscount1 } from "react-icons/ci";
import { GiMoneyStack } from "react-icons/gi";

import Layout from '../../Components/Layout/Layout';

import axios from "../../config/axios";
import moment from "../../config/moment";
import { formattedAmount } from "../../utils/helpers";
import styles from "./Landing.module.scss";
import Last7DaysOrders from "../../Components/Graphs/Last7DaysOrders";
import Last7DaysSales from "../../Components/Graphs/Last7DaysSales";
import Loader from "../../Components/Loader/Loader";


const Landing = (props) => {
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [refresh, setRefresh] = useState(null);
    const [stat, setStat] = useState(null);
    const [graphStat, setGraphStat] = useState(null);


    useEffect(() => {
        axios.get(`/admin/reports/basic-stat?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setStat(response.data);
        });

        axios.get(`/admin/reports/graph-stat`, {withCredentials: true}).then((response) => {
            setGraphStat(response.data);
        });

    }, [startDate, endDate, refresh]);

    return (
        <>
            <Layout>
                <Row gutter={[10, 10]}>
                    <Col xs={24} lg={12}>
                        {/*<h2 className="breadcrumb-custom mb-0 mt-2">*/}
                        {/*    <span>Dashboard</span> {">"} <span>Landing</span>*/}
                        {/*</h2>*/}
                        <div className={"d-flex align-items-center"}>
                            <h2 className={"m-0 " + styles.title}>Realtime Overview</h2>
                            <img
                                src="/live.svg"
                                style={{width: "30px", marginLeft: "1rem"}}
                                alt=""
                                onClick={() => setRefresh(Math.random())}
                            />
                            {/*<Button style={{marginLeft: '1rem'}} type={'dashed'}*/}
                            {/*        onClick={() => setRefresh(Math.random())}>*/}
                            {/*    /!*<FiRefreshCcw/>*!/*/}
                            {/*</Button>*/}
                        </div>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <DatePicker.RangePicker
                                size={"large"}
                                style={{width: window.innerWidth < 576 ? "100%" : null}}
                                placeholder={["From Date", "To Date"]}
                                onChange={(_, dateString) => {
                                    setStartDate(dateString[0]);
                                    setEndDate(dateString[1]);
                                }}
                                defaultValue={[moment(), moment()]}
                                format={"YYYY-MM-DD"}
                            />
                        </div>
                    </Col>
                </Row>

                <div className="mt-3"/>

                {/* First Row */}
                <Row gutter={[16, 16]}>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-secondary">Orders</h1>
                                    <h2 className="mb-1 text-primary">
                                        {stat ? stat.no_of_orders : "..."}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3"
                            >

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Order Received</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? stat.no_of_or_orders : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Order Packed</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? stat.no_of_op_orders : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Order on The Way</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? stat.no_of_otw_orders : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Delivered</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? stat.no_of_d_orders : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Cancelled</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? stat.no_of_c_orders : "..."}
                                    </span>
                                </div>

                            </div>

                        </Card>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-danger">Sales</h1>
                                    <h2 className="mb-1 text-primary">
                                        {stat ? `${formattedAmount(stat.total_sales_amount)} €` : "..."}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3"
                            >

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Collected Amount</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? `${formattedAmount(stat.total_collected_amount)} €` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Due Amount</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? `${formattedAmount(stat.total_due_amount)} €` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Cancelled Amount</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? `${formattedAmount(stat.total_cancelled_amount)} €` : "..."}
                                    </span>
                                </div>


                            </div>

                        </Card>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-success">New Registration</h1>
                                    <h2 className="mb-1 text-primary">
                                        {stat ? stat.no_of_new_registration : '...'}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3"
                            >

                                <div className="d-flex justify-content-between text-lt">
                                    <span className={"text-secondary"}>
                                        <strong>Unique Buyers</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {stat ? stat.no_of_unique_buyer : '...'}
                                    </span>
                                </div>
                            </div>

                        </Card>
                    </Col>

                </Row>
                <br/>
                {/*<Row gutter={[10, 10]}>*/}
                {/*    /!*<Col xs={24} lg={4} xl={8}>*!/*/}
                {/*    /!*    <div className={'d-flex align-items-center'}>*!/*/}
                {/*    /!*        <h2 className={'m-0'}>Reports</h2>*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*    /!*<Col xs={24} lg={20} xl={16}>*!/*/}
                {/*    /!*    <div className={'d-flex align-items-center'}>*!/*/}
                {/*    /!*        <h2 className={'m-0'}>Last 7 Days Statistics</h2>*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*    <Col xs={24} lg={4} xl={8}>*/}
                {/*        <div className={"d-flex align-items-center"}>*/}
                {/*            <h2 className={"m-0"}>Reports</h2>*/}
                {/*        </div>*/}
                {/*        <br/>*/}

                {/*        /!*<div className={"card p-4 d-flex flex-column gap-4"}>*!/*/}
                {/*        /!*    <Button type={"primary"} size={"large"} className={"w-100"}*!/*/}
                {/*        /!*            onClick={() => setShowStockReport(true)}>*!/*/}
                {/*        /!*        Stock Reports*!/*/}
                {/*        /!*    </Button>*!/*/}
                {/*        /!*    <Button type={"primary"} size={"large"} className={"w-100"}*!/*/}
                {/*        /!*            onClick={() => setShowDateWiseItemsSold(true)}>*!/*/}
                {/*        /!*        Date Wise Items Sold*!/*/}
                {/*        /!*    </Button>*!/*/}
                {/*        /!*    <Button type={"primary"} size={"large"} className={"w-100"}*!/*/}
                {/*        /!*            onClick={() => props.history.push('/send-bulk-sms')}>*!/*/}
                {/*        /!*        Send Bulk SMS*!/*/}
                {/*        /!*    </Button>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </Col>*/}

                {/*</Row>*/}
                {/*<br/>*/}
                <Divider />
                <Row gutter={[20, 20]}>
                    <Col lg={12}>
                        <h2 className={"m-0 " + styles.title}>Orders - Last 7 Days</h2>
                        <div className={'card'}>
                            {
                                graphStat ?
                                <Last7DaysOrders orderData={graphStat.last_7_days_orders}/>
                                    :
                                    <Loader height={'400px'} />
                            }
                        </div>
                    </Col>
                    <Col lg={12}>
                        <h2 className={"m-0 " + styles.title}>Sales - Last 7 Days</h2>
                        <div className={'card'}>
                            {
                                graphStat ?
                                <Last7DaysSales salesData={graphStat.last_7_days_sales}
                                                avgSalesData={graphStat.average_order_value}/>
                                    :
                                    <Loader height={'400px'} />
                            }
                        </div>
                    </Col>
                </Row>

            </Layout>
        </>
    );
};

export default Landing;
