import { useState } from 'react';
import styles from './OrderItem.module.scss';
import axios from '../../config/axios';

//importing components
import { Button, message, Modal, Select, Tag } from 'antd';
import { order_status } from "../../utils/static";


const OrderItem = ({order}) => {
    const [showOrder, setShowOrder] = useState(false);

    const patchOrder = status => {
        axios.patch(`/admin/orders/${order.id}`, {status: status}, {withCredentials: true}).then(response => {
            message.success('Order updated successfully!');
        });
    }

    return (
        <>
            <tr>
                <td>
                    <Tag color={'var(--color-primary)'} className={'fs-3'}>{order.id}</Tag>
                </td>
                <td>
                    <div className={'d-flex flex-column gap-2'}>
                        <span><span
                            className={'fw-bold'}>Name:</span> {order.title} {order.first_name} {order.last_name}</span>
                        <span><span className={'fw-bold'}>Email:</span> <a href={`mailto:${order.email}`}
                                                                           target={'_blank'}>{order.email}</a></span>
                        <span><span className={'fw-bold'}>Phone:</span> <a href={`tel:${order.phone_number}`}
                                                                           target={'_blank'}>{order.phone_number}</a></span>
                    </div>
                </td>
                <td style={{width: '200px'}}>
                    <div className={'d-flex flex-column'}>
                        <span><span className={'fw-bold'}>House No:</span> {order.house_no}</span>
                        <span><span className={'fw-bold'}>Street Address:</span> {order.street_address}</span>
                        {
                            order.state &&
                            <span><span className={'fw-bold'}>State:</span> {order.state}</span>
                        }
                        <span><span className={'fw-bold'}>City:</span> {order.city}</span>
                        <span><span className={'fw-bold'}>Zip Code:</span> {order.zip_code}</span>
                        {/*<span>Country:{order.country}</span>*/}
                    </div>

                </td>
                <td>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span><span
                            className={'fw-bold'}>Delivery Fee:</span> {order.delivery_fee ? order.delivery_fee : 'N/A'}</span>
                        <span>Delivery Method: <Tag color={'var(--color-primary)'}
                                                    className={'fs-5'}>{order.delivery_method ? order.delivery_method.toUpperCase() : 'N/A'}</Tag></span>
                    </div>
                </td>
                <td>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span>Payment Method: {order.payment_method}</span>
                        <span>Gateway ID: {order.payment_gateway_ref ? order.payment_gateway_ref : 'N/A'}</span>
                    </div>
                </td>
                <td>
                    <Tag
                        className={'fs-5'}
                        color={order.payment_status === 'pending' ? 'red' : 'var(--color-primary)'}
                    >
                        {order.payment_status.toUpperCase()}
                    </Tag>
                </td>
                <td>
                    <Select
                        defaultValue={order.status}
                        onChange={value => patchOrder(value)}
                    >
                         {
                                            order_status.map((status, index)=>{
                                                return <Select.Option key={index} value={status.value}>{status.label}</Select.Option>
                                            })
                                        }
                    </Select>
                </td>
                <td>
                    <div className={'d-flex flex-column gap-3'}>
                        <Button type={'primary'} onClick={() => setShowOrder(true)}>Quick View</Button>
                        <Button type={'dashed'} style={{borderColor: 'var(--color-primary)', color: 'var(--color-primary)'}} onClick={() => setShowOrder(true)}>Details</Button>
                        <Button type={'dashed'} style={{borderColor: 'var(--color-primary)', color: 'var(--color-primary)'}} onClick={() => setShowOrder(true)}>Print Invoice</Button>
                    </div>
                </td>
            </tr>
            {
                showOrder &&
                <Modal
                    visible={showOrder}
                    centered
                    footer={null}
                    onCancel={() => setShowOrder(false)}
                    style={{width: '70vw'}}
                    width="70vw"
                >
                    <table className='table' style={{marginTop: '2rem'}}>
                        <thead>
                        <th>Product</th>
                        <th>Title</th>
                        <th>Image</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                        </thead>
                        {
                            order.order_items.map((item, index) => {
                                return <tr key={index}>
                                    <td style={{width: '250px'}}>
                                        Product ID: {item.variant.product}
                                        <br/>
                                        Variant Type: {item.variant.type}
                                        <br/>
                                        Variant Value: {item.variant.value}
                                    </td>
                                    <td>
                                        {item.title}
                                    </td>
                                    <td>
                                        <img src={item.image} width='50px' alt=""/>
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    <td>€{item.order_time_price}</td>
                                    <td>€{parseFloat(parseFloat(item.order_time_price) * item.quantity).toFixed(2)}</td>
                                </tr>
                            })
                        }
                    </table>
                </Modal>
            }
        </>
    );
};

export default OrderItem;